import React from 'react'
import { 
  useLocation, 
  useParams 
} from 'react-router-dom';
import type { 
  Location, 
  Params 
} from 'react-router-dom';

interface useLayoutValueInterface {
  activePageRoute?: string | number | null,
  activePageName?: string | number | null,
  breadcrumb?: Array<string | number>,
  canRead?: boolean,
  canWrite?: boolean,
  canDelete?: boolean,
}

interface useLayoutDispatchInterface {
  type: 'SET_VALUE' | 'SET_ACTIVE_PAGE',
  payload?: useLayoutValueInterface
}

// layout Reducer Action:
const layoutReducerAction = (prevState: useLayoutValueInterface, action: useLayoutDispatchInterface) => {
  const { type, payload } = action

  if (type === 'SET_VALUE') {
    return {
      ...prevState,
      ...payload
    }
  }

  if (type === 'SET_ACTIVE_PAGE') {
    return {
      ...prevState,
      activePageRoute: payload?.activePageRoute
    }
  }

  return prevState
}

// Inisiasi layout context
const LayoutContext = React.createContext<any>({})

// Layout Provider: 
// sama seperti context provider
export const LayoutProvider: React.FC<any> = ({children}) => {
  const [state, dispatch] = React.useReducer<any>(layoutReducerAction, {
    activePageRoute: null,
    activePageName: null,
    canRead: false,
    canWrite: false,
    canDelete: false,
    breadcrumb: []
  })

  return (
    <LayoutContext.Provider value={{state, dispatch}}>
      {children}
    </LayoutContext.Provider>
  )
}

// useLayout:
// Custom hooks yang digunakan untuk mengelola layout context
// Aksi yang dapat digunakan pada useLayout
export const useLayout = () => {
  const location = useLocation()
  const params = useParams()
  const { state, dispatch } = React.useContext(LayoutContext)

  // Mendapatkan url route tanpa params
  // ex: /test/link/:id => /test/link/
  const getActivePageWithoutParams = (location: Location, params: Params): string => {
    const { pathname } = location
  
    if (!Object.keys(params).length) {
      return pathname + '/'
    }
  
    let path = pathname

    Object.entries(params).forEach(([_paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, '')
      }
    })

    return path
  }

  // Mengganti semua nilai pada useLayout
  const setValue = (val: useLayoutValueInterface) => dispatch({
    type: 'SET_VALUE',
    payload: {
      ...val
    }
  })

  // Set nilai active page route (link)
  // ex: /test/link 
  const setActivePageRoute = (val: string | number) => dispatch({
    ...state,
    type: 'SET_ACTIVE_PAGE',
    payload: {
      activePageRoute: val
    }
  })

  return {
    getBreadcrumb: () => state.breadcrumb,
    getActivePageName: () => state.activePageName,
    getActivePageRoute: () => state.activePageRoute,
    getInfo: () => state,
    getActivePageRouteWithoutParams: () => getActivePageWithoutParams(location, params),
    canRead: () => state.canRead,
    canWrite: () => state.canWrite,
    canDelete: () => state.canDelete,
    setActivePageRoute: (val: string | number) => setActivePageRoute(val),
    setValue: (val: useLayoutValueInterface) => setValue(val)
  }
}