import React from 'react'
import {
  Modal as BSModal,
} from 'react-bootstrap'
import {
  HiPlus
} from 'react-icons/hi'
import {
  ModalInterface
} from '../Modal'
import { 
  ButtonCancel, 
  ButtonCreate 
} from '../../Button'

export interface ModalCreateInterface extends ModalInterface {
  customTitle?: any,
  customSubmitButtonText?: string | number,
  customCancelButtonText?: string | number,
  submitLoading?: boolean,
  onSubmit?: () => void,
  onCancel?: () => void
}

export const ModalCreate: React.FC<ModalCreateInterface> = ({
  show,
  children,
  closeButton = true,
  title,
  customTitle,
  titleClassName,
  titleStyle,
  submitLoading,
  scrollable = false,
  customSubmitButtonText,
  customCancelButtonText,
  onHide,
  onCancel,
  onSubmit,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      show={show}
      onHide={submitLoading ? () => {} : onHide}
      scrollable={scrollable}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-primary text-capitalize`}
          style={titleStyle}
        >
          {customTitle 
            ? customTitle
            : <>
                <HiPlus className="me-2 mr-2" /> Tambah Data {title}
              </>
          }
        </div>
      </BSModal.Header>
      <BSModal.Body>
        {show
          ? <>{children}</>
          : <></>
        }
      </BSModal.Body>
      <BSModal.Footer>
        <ButtonCancel 
          text={customCancelButtonText}
          disabled={submitLoading}
          onClick={onCancel ? onCancel : onHide} 
        />
        <ButtonCreate 
          type="submit"
          text={customSubmitButtonText}
          loading={submitLoading}
          onClick={onSubmit}
          className="px-3"
        />
      </BSModal.Footer>
    </BSModal>
  )
}