import React from 'react'
import {
  Row,
  Col,
  Breadcrumb
} from 'react-bootstrap'
import {
  useLayout
} from '../../hooks'

export interface ContentLayoutInterface extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  title?: string,
  breadcrumb?: (string)[],
  showBreadcrumb?: boolean,
  customLeftSection?: React.ReactNode
  customRightSection?: React.ReactNode
}

export const ContentLayout: React.FC<ContentLayoutInterface> = ({
  className,
  children,
  title,
  breadcrumb,
  showBreadcrumb,
  customLeftSection,
  customRightSection,
  ...props
}) => {
  const layout = useLayout()
  const PAGE_NAME = layout.getActivePageName()
  const BREADCRUMB = layout.getBreadcrumb()
  const generateBreadcrumb = breadcrumb ?? BREADCRUMB

  return (
    <div 
      {...props} 
      className={`${className ? className : ''} page-layout bg-white border rounded`}
    >
      <div 
        className="position-sticky bg-white px-3 pt-3 border-bottom rounded-top" 
        style={{
          top: '65px', 
          zIndex: 1990
        }}
      >
        <Row className={showBreadcrumb ? 'pb-3 pb-sm-0' : 'pb-3'}>
          <Col lg>
            {customLeftSection
              ? customLeftSection
              : <b className="text-capitalize" style={{fontSize: 17}}>
                  {title ?? PAGE_NAME}
                </b>
            }
          </Col>
          <Col lg className="breadcrumb-wrapper pb-0 d-flex justify-content-lg-end overflow-auto">
            {customRightSection
              ? customRightSection
              : showBreadcrumb && 
                <Breadcrumb style={{fontSize: 15}}>
                  {generateBreadcrumb.map((val: string, index: number) => (
                    <Breadcrumb.Item 
                      active
                      key={index}
                      className="text-capitalize"
                    >
                      {generateBreadcrumb.length - 1 === index
                        ? <b className="text-dark" style={{opacity: .7}}>{val}</b>
                        : val
                      }
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
            }
          </Col>
        </Row>
      </div>
      <div className="p-3">
        {children}
      </div>
    </div>
  )
}
