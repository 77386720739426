import React from 'react'
import {
  InputGroup,
  FormControlProps,
  Form
} from 'react-bootstrap'
import {
  HiSearch
} from 'react-icons/hi'

export const InputSearch: React.FC<FormControlProps> = ({
  placeholder,
  size = 'sm',
  ...props
}) => {
  return (
    <InputGroup size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}>
        <InputGroup.Text style={{height: '31px'}}>
            <HiSearch />
        </InputGroup.Text>
      <Form.Control
        {...props}
        type="text"
        placeholder={placeholder ?? 'Cari data'}
        style={{
          ...props.style,
          height: '31px'
        }}
      />
    </InputGroup>
  )
}