import React from "react"
import { 
  Button, 
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  HiPencilAlt
} from 'react-icons/hi'
import {
  ButtonCreateInterface
} from '../ButtonCreate'

export const ButtonUpdate: React.FC<ButtonCreateInterface> = ({
  variant = "warning",
  text,
  icon = false,
  tooltip = true,
  tooltipText,
  tooltipPlacement,
  loading,
  loadingText,
  size = 'sm',
  disabled,
  style,
  noText,
  ...rest
}) => {
  const checkIsVariantOutline = (variant: String) => Boolean(variant.substring(0,7) === 'outline')

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText ?? 'Ubah data'}</Tooltip> : <div></div>}
    >
      <Button
        {...rest}
        size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
        variant={variant ?? "warning"}
        disabled={loading ? true : disabled ? true : false}
        style={{
          ...style,
          minWidth: icon ? 'auto' : '100px' 
        }}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <Spinner 
                animation="border" 
                variant={checkIsVariantOutline(variant ?? 'warning') ? variant : 'white'}
                size="sm" 
              />
              {Boolean(text) &&
                <div className={`ms-2 ml-2 ${variant === 'warning' || variant === "info" ? 'text-white' : ''}`}>
                  {loadingText ?? "Memproses . . ."}
                </div>
              }
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center text-white">
            <div>
              {icon && <HiPencilAlt className="mb-1"/>}
            </div>
            {!noText && (
              <span className="mx-1 text-uppercase">
                {text  ? text : 'Ubah'}
              </span>
            )}
          </div>
        )}
      </Button>
    </OverlayTrigger>
  )
}