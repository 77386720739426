import React from 'react'
import { 
  Button as BSButton, 
  ButtonProps,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  ButtonInterface
} from "../Button"

interface ButtonFilterInterface extends  ButtonInterface {
  active: boolean,
  textActiveTrue?: string | number,
  textActiveFalse?: string | number
}

export const ButtonFilter: React.FC<ButtonFilterInterface> = ({
  active,
  text,
  textActiveTrue,
  textActiveFalse,
  variant,
  children,
  disabled,
  loading,
  loadingText,
  tooltip,
  tooltipText,
  tooltipPlacement,
  className,
  size = 'sm',
  ...rest
}) => {

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
    >
      <BSButton
        {...rest}
        size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
        className={`${className ?? ''} ${variant === 'warning' || variant === "info" ? 'text-white' : ''} text-uppercase`}
        variant={variant ?? 'primary'}
        disabled={loading ? true : disabled ? true : false}
      >
        {text ? text : 'Filter:'} <b>{active ? textActiveTrue ? textActiveTrue : 'ON' : textActiveFalse ? textActiveFalse : 'OFF'}</b>
      </BSButton>
    </OverlayTrigger>
  )
}