import React from 'react'
import {
  Modal as BSModal,
} from 'react-bootstrap'
import {
  HiEye
} from 'react-icons/hi'
import {
  ButtonCancel
} from '../../../components'
import {
  ModalCreateInterface
} from '../ModalCreate'

interface ModalDetailInterface extends ModalCreateInterface {
  showCloseButton?: boolean,
  closeButtonText?: any,
  onCloseButtonClick?: () => void,
}

export const ModalDetail: React.FC<ModalDetailInterface> = ({
  show,
  showCloseButton = true,
  closeButtonText,
  children,
  closeButton = true,
  title,
  customTitle,
  titleClassName,
  titleStyle,
  scrollable = false,
  onHide,
  onCloseButtonClick,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      show={show}
      scrollable={scrollable}
      onHide={onHide}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-info text-capitalize`}
          style={titleStyle}
        >
          {customTitle 
            ? customTitle
            : <>
                <HiEye className="me-2 mr-2" /> Detail Data {title}
              </>
          }
        </div>
      </BSModal.Header>
      <BSModal.Body>
        {show 
          ? <>
              {children}
            </>
          : <></>
        }
      </BSModal.Body>
      {showCloseButton &&
        <BSModal.Footer>
          <ButtonCancel 
            text={closeButtonText ? closeButtonText : 'Tutup'}
            className='m-1' 
            onClick={onCloseButtonClick ? onCloseButtonClick : onHide}
          />
        </BSModal.Footer>
      }
    </BSModal>
  )
}