import React, { useEffect } from "react"
import ReactPaginate from "react-paginate"
import { Row, Col } from "react-bootstrap"
import { Select } from "../../components"

interface DataLengthInterface {
  label: string | number,
  value: string | number
}

export interface PaginationInterface {
  dataLength: number,
  dataTotal: number,
  pageCurrent: number,
  pageTotal: number,
  dataLengthOption?: Array<DataLengthInterface>
  onDataLengthChange?: (val: any) => void,
  onPaginationChange?: (val: any) => void
}

export const Pagination: React.FC<PaginationInterface> = ({
  dataLength,
  dataTotal,
  pageCurrent,
  pageTotal,
  dataLengthOption,
  onPaginationChange,
  onDataLengthChange,
}) => {
  const dataPage = dataTotal < dataLength ? dataTotal : pageCurrent * dataLength
  const dataStart = pageCurrent * dataLength - dataLength + 1
  const dataEnd = dataTotal < dataPage ? dataTotal : dataPage
  const dataRange = `${dataStart} - ${dataEnd}`
  const dataLengthOptionDefault = [
    {label: '10 data', value: 10},
    {label: '25 data', value: 25},
    {label: '50 data', value: 50},
    {label: '75 data', value: 75},
    {label: '100 data', value: 100}
  ]  

  useEffect(() => {
  
  }, [dataLength, dataTotal, pageCurrent, pageTotal])
  
  
  return (
    <Row className="overflowX-scroll mt-4">
      <Col xs={{order: 3}} md={{span: 4, order: 1}} className="text-center text-md-left text-md-start">
        <p className="mt-1" style={{fontSize: '14px', opacity: .8}}>
          Menampilkan {dataRange} data dari {dataTotal} data
        </p>
      </Col>
      <Col xs={{order: 1}} md={{span: 4, order: 2}}>
        <ReactPaginate
          pageCount={pageTotal}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName="pagination pagination-sm justify-content-center"
          pageLinkClassName="page-link"
          breakClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="page-item active"
          disabledClassName="page-item disabled"
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          onPageChange={(e: any) => onPaginationChange && onPaginationChange(e.selected + 1)}
          initialPage={pageCurrent - 1}
          disableInitialCallback={true}
        />
      </Col>
      <Col xs={{order: 2}} md={{span: 4, order: 3}} className="d-flex justify-content-center justify-content-md-end">
        <p className="me-2 mt-1" style={{fontSize: '14px', opacity: .8}}>Tampilkan :</p>
        <Select 
          defaultValue={dataLengthOption ? dataLengthOption.find(val => val.value === dataLength) : dataLengthOptionDefault.find(val => val.value === dataLength)} 
          wrapperStyle={{ width: "125px" }} 
          menuPortalTarget={document.body}
          portalZIndex={3000}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 3000,
            })
          }}
          onChange={(e: any) => onDataLengthChange && onDataLengthChange(e.value)}
          options={dataLengthOption ? dataLengthOption : dataLengthOptionDefault}
        />
      </Col>
    </Row>
  )
}
