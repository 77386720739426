import React from 'react'

export interface RadioButtonInterface extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
  id: string,
  name: string,
  label?: string
  error?: boolean,
  wrapperClassName?: string
}

export const RadioButton: React.FC<RadioButtonInterface> = ({
  id, 
  label, 
  error, 
  className,
  wrapperClassName,
  ...rest
}) => {
  return (
    <div className={`form-check ${wrapperClassName}`}>
      <input 
        {...rest}
        id={id}
        type="radio"
        className={`form-check-input ${error && 'is-invalid'} ${className}`} 
      />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  )
}