import React, {
  useRef,
  useState,
  useCallback,
} from 'react'
import { 
  ButtonGroup 
} from 'react-bootstrap'
import { 
  HiDocumentAdd
} from 'react-icons/hi'
import FileSaver from 'file-saver'
import {
  ButtonCreate,
  ButtonDelete,
  ButtonDownload,
  ModalDelete,
  Table,
  Th,
  Td,
} from '../../../../components'

interface DataInterface {
  data: any, 
  name: string, 
  link: string
}

interface ModalDeleteConfigInterface {
  index: number,
  show: boolean,
  loading: boolean,
  data: string,
}

export interface InputFileInterface {
  type: 'action' | 'view',
  data: (DataInterface)[],
  acceptFileType?: (string)[]
  createButtonPosition?: 'start' | 'center' | 'end',
  createButtonText?: string | number,
  showCreateButton?: boolean,
  setData: (data: (DataInterface)[]) => void
}

export const InputFileMultiple: React.FC<InputFileInterface> = ({
  type: TYPE = 'action', 
  data,
  showCreateButton = true, 
  createButtonPosition = 'end',
  createButtonText = 'Tambah File',
  acceptFileType = ['pdf', 'xlsx', 'dwg', 'docx', 'skp', 'zip', 'rar'],
  setData
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [modalDeleteConfig, setModalDeleteConfig] = useState<ModalDeleteConfigInterface>({
    index: 0,
    show: false,
    loading: false,
    data: ''
  })

  const filterFileHandler = data.map((val, index) => ({...val, index: index})).filter((val, index) => {
    const file = val.name ? val.name : val.link
    const getFileType = file?.split('.') ? String(file?.split('.').pop()) : ''
    
    // Check jika type file sudah benar
    if (acceptFileType.includes(getFileType)) {    
      return {
        ...val,
        index: index
      }
    } else {
      return false
    }
  })

  // Trigger input file agar terclick
  const onClickFilesHandler = () => Boolean(TYPE !== 'view') && Boolean(inputFileRef.current && inputFileRef.current.click())

  // Menangani saat file dipilih
  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    
    // Check apakah ada file yang dipilih
    if (!file) return null 
    
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const generalFileType = file.name.split('.').pop()

    if (acceptFileType.includes(generalFileType ? generalFileType : '')) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)
      const finalValue = {data: file, name: generateName, link: generateLink}
      
      return setData([...data, finalValue])

    } else {
      return window.alert(`Format file tidak valid, harap masukan file berformat ${acceptFileType.join(', ')}`)
    }
  }

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({...modalDeleteConfig, loading: true})
    const filterData = data.filter((_val, index) => index !== modalDeleteConfig.index)

    setTimeout(() => {
      setData(filterData)
      setModalDeleteConfig({
        index: 0,
        show: false,
        loading: false,
        data: ''
      })
    }, 300)
  },[modalDeleteConfig])

  return (
    <div>
      {/* Button Section */}
      <div className={`mb-3 text-${createButtonPosition}`}>
        <input 
          ref={inputFileRef} 
          type="file" 
          style={{display: 'none'}} 
          onChange={onChangeFileHandler}
        />
        {Boolean(TYPE !== 'view' &&  showCreateButton) &&
          <ButtonCreate
            tooltip={false}
            text={createButtonText} 
            onClick={onClickFilesHandler} 
          />
        }
      </div>

      {/* Table */}
      <Table>
        <thead className="bg-light">
          <tr>
            <Th width={50}>No</Th>
            <Th>Nama File</Th>
            <Th width={60}>Aksi</Th>
          </tr>
        </thead>
        <tbody>
          {filterFileHandler && filterFileHandler.length > 0
            ? filterFileHandler.map((val, index) => (
                <tr key={index}>
                  <Td textCenter>{String(index + 1)}</Td>
                  <Td>{val.name}</Td>
                  <Td className="text-center">
                    <ButtonGroup>
                      {/* Button Download */}
                      <ButtonDownload
                        noText
                        size="sm"
                        onClick={() => FileSaver.saveAs(val.link, val.name)}
                      />

                      {/* Button Delete */}
                      {TYPE !== 'view' &&
                        <ButtonDelete
                          noText
                          icon
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setModalDeleteConfig({
                              show: true,
                              loading: false,
                              index: val.index,
                              data: val.name
                            })
                          }}
                        />
                      }
                    </ButtonGroup>
                  </Td>
                </tr>
              ))
            : <tr 
                style={{cursor: 'pointer'}}
                onClick={onClickFilesHandler}
                className="bg-light"
              >
                <Td noPadding colSpan={3}>
                  <div className="d-flex flex-column justify-content-center align-items-center bg-light py-5">
                    <HiDocumentAdd size={50} className="text-secondary" />
                    <span style={{fontSize: 16}} className="mt-2 text-secondary">Tidak ada file</span>
                  </div>
                </Td>
              </tr>
          }
        </tbody>
      </Table>

      {/* Modal */}
      <ModalDelete
        show={modalDeleteConfig.show} 
        title="File"
        submitLoading={modalDeleteConfig.loading}
        onSubmit={onDeleteHandler}
        onHide={() => setModalDeleteConfig({
          show: false,
          index: 0,
          loading: false,
          data: ''
        })}
      >
        Data dengan nama file <b className="text-danger">{modalDeleteConfig.data}</b>
      </ModalDelete>
    </div>
  )
}