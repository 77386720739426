import React from 'react' 

interface TbodyInterface extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>, 'className'> {
  className?: string
}
export const Tbody: React.FC<TbodyInterface> = ({
  children,
  ...props
}) => {
  return (
    <tbody
      {...props}
    >
      {children}
    </tbody>
  )
}