import React from 'react';

export interface InfoItemInterface
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label?: string | number | undefined | null;
  text?: string | number | undefined | null;
  width?: number;
  fontSize?: number | string;
}

export const InfoItemHorizontal: React.FC<InfoItemInterface> = ({
  label,
  text,
  width,
  className,
  fontSize,
  ...props
}) => (
  <div {...props} className={`d-flex align-items-top ${className}`}>
    <div style={{ width: width ? width : 180, fontSize: fontSize ?? 14 }}>
      {label ? label : '-'}
    </div>
    <div className="ps-3 pl-3 pe-2 pr-2" style={{ fontSize: fontSize ?? 14 }}>
      :
    </div>
    <div style={{ fontSize: fontSize ?? 14 }}>{text ? text : '-'}</div>
  </div>
);
