export const asyncSubmitValidator = async (data: Array<{validator: Promise<any>, message: string, field: string, disableValidator?: boolean}>) => {
  const check = await Promise.all(data.map(async (val) => {
    const { validator, message, field, disableValidator } = val

    return disableValidator 
      ? { error: false, message, field} 
      : validator.then(() => ({error: false, message, field})).catch(() => ({error: true, message, field}))
  }))

  return {
    error: check.filter(({error}) => error).length > 0,
    errorDataArray: check.filter(({error}) => error),
    errorDataObject: check.filter(({error}) => error).reduce((prev, current) => ({...prev, [current.field]: current.message}), {}),
    successDataArray: check.filter(({error}) => !error),
    successDataObject: check.filter(({error}) => !error).reduce((prev, current) => ({...prev, [current.field]: current.message}), {}),
    allDataArray: check,
    allDataObject: check.reduce((prev, current) => ({...prev, [current.field]: current.message}), {})
  }
}