import React, {
  useState,
  useEffect
} from 'react'
import {
  EditorState
} from 'draft-js'
import {
  Editor,
  EditorProps,
} from 'react-draft-wysiwyg'
import {
  convertToRaw,
  ContentState,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export interface TextEditorInterface extends Omit<EditorProps, "onChange"> {
  label?: string,
  value?: any,
  height?: number | string,
  error?: boolean,
  errorText?: string,
  style?: React.CSSProperties,
  wrapperStyle?: React.CSSProperties,
  wrapperClassName?: string,
  options?: (any)[]
  onChange?: (callback: any) => void
}

export const TextEditor: React.FC<TextEditorInterface> = ({
  label,
  value,
  height,
  wrapperClassName,
  wrapperStyle,
  style,
  error,
  errorText,
  options,
  onChange,
  ...props
}) => {
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty())
  const [isFocused, setIsFocused] = useState<boolean>(false)

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(`${value ?? ''}`)
    const {contentBlocks, entityMap} = blocksFromHTML
    const state = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks,entityMap))

    setEditorState(state)
  }, [])
  
  return (
    <div className={`mb-2 ${wrapperClassName}`} style={wrapperStyle}>
      <label>
        <small>
          {label}
        </small>
      </label>
      <Editor
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        editorClassName={`text-editor ${isFocused && 'focused'} ${error && 'is-invalid'}`}
        editorStyle={{
          height: height ?? '',
          paddingLeft: 10,
          paddingRight: 10,
          background: 'white',
          fontSize: 14
        }}
        toolbar={{
          options: options ?? ['inline', 'list', 'textAlign'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          }
        }}
        editorState={editorState}
        onEditorStateChange={(value: any) => {
          const convertRaw = convertToRaw(value.getCurrentContent())
          const convertToHTML = draftToHtml(convertRaw)
          setEditorState(value)
          onChange && onChange(convertToHTML)
        }}
    />
      <small className="mt-1 text-danger">
        {errorText}
      </small>
    </div>
  )
}

export default TextEditor