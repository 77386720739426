import React from 'react'
import {
  InfoItemInterface
} from '../InfoItemHorizontal'

interface InfoItemVerticalInterface extends InfoItemInterface {
  labelFontSize?: string | number
}

export const InfoItemVertical: React.FC<InfoItemVerticalInterface> = ({
  label, 
  text, 
  className,
  labelFontSize,
  fontSize,
  ...props
}) => (
  <div 
    {...props}
    className={`d-flex flex-column mb-2 ${className}`} 
  >
      <small style={{fontSize: labelFontSize ?? 12}}>{label ? label : '-'}</small>
      <strong style={{fontSize: fontSize ?? 14}}>{text ? text : '-'}</strong>
  </div>
)

export default InfoItemVertical