import React from 'react'
import {
  Tabs as BSTabs,
  Tab as BSTab,
  TabsProps as BSTabsProps
} from 'react-bootstrap'

interface TabInterface {
  title: string | number,
  key: string | number,
  component: React.FC<any>, 
}

export interface TabsInterface extends BSTabsProps {
  tabs: Array<TabInterface>
}

export const Tabs: React.FC<TabsInterface> = ({
  tabs,
  className,
  ...props
}) => {
  return (
    <BSTabs
      {...props}
      className={`rounded-top bg-light border ${className}`}
    >
      {tabs.map((val, index) => {
        const { key, title, component } = val
        return (
          <BSTab 
            key={index}
            title={title}
            eventKey={key}
            className="border-bottom border-start p-3 rounded-bottom border-end"
            tabClassName={`${index === 0 ? 'border-start-0' : ''} border-top-0 border-bottom-0`}
          >
            {component}
          </BSTab>
        )
      })}
    </BSTabs>
  )
}