import React from 'react' 

interface TfootInterface extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>, 'className'> {
  className?: string
}

export const Tfoot: React.FC<TfootInterface> = ({
  className,
  style,
  children,
  ...props
}) => {
  return (
    <tfoot 
      {...props}
      className={`${className ?? ''}`}
      style={{
        ...style,
        zIndex: 110,
        insetBlockEnd: 0
      }}
    >
      {children}
    </tfoot>
  )
}