interface numberConvertProps {
  minimumFractionDigits: number,
  maximumFractionDigits: number,
}

export const numberConvert = () => {
  const getWithComa = (number: any, props?: numberConvertProps) => {
    const convertToNumber = parseFloat(number)
    const checkConvert = convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber))

    return new Intl.NumberFormat('id-ID', {minimumFractionDigits: props?.minimumFractionDigits ?? 2, maximumFractionDigits: props?.maximumFractionDigits ?? 2}).format(checkConvert)
  }

  return {
    getWithComa: (number: any, props?: numberConvertProps) => getWithComa(number, props)
  }
}