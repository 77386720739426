import React from 'react'
import {
  Modal as BSModal,
  ModalProps as BSModalProps,
  Alert
} from 'react-bootstrap'
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoAlertCircleOutline
} from 'react-icons/io5'
import {
  Button,
  ButtonCancel,
  ButtonCreate,
  ButtonUpdate,
  ButtonDelete
} from '../../../components'
import { 
  InfoItemVertical 
} from '../../InfoItem'

export interface ModalConfirmInterface extends Omit<BSModalProps, 'title'> {
  title?: string | number,
  titleClassName?: string | number,
  titleStyle?: React.CSSProperties,
  size?: 'sm' | 'lg' | 'xl',
  alert?: boolean,
  alertVariant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light',
  customTextHeader?: any,
  customTextInfoTitle?: any,
  customTextInfoDetail?: any,
  customAlertText?: string | number,
  customButtonShow?: boolean,
  customButtonText?: string | number,
  customButtonVariant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light',
  customButtonClassName?: string,
  customButtonStyle?: React.CSSProperties,
  cancelButtonShow?: boolean,
  cancelButtonText?: string | number,
  customButtonOnClick?: () => void,
  type?: 'create' | 'update' | 'delete' | 'error' | 'info' | 'custom',
  typeInfo?: 'success' | 'error' | 'info',
  typeInfoDetail?: 'create' | 'update' | 'delete',
  data?: ({label: string | number, text: string | number})[],
  show?: boolean,
  submitLoading?: boolean,
  component?: any,
  onSubmit?: () => void,
  onHide?: () => void,
  onCancel?: () => void,
}

export const ModalConfirm: React.FC<ModalConfirmInterface> = ({
  show,
  size,
  title,
  titleClassName,
  titleStyle,
  alert,
  alertVariant = 'danger',
  type,
  typeInfo,
  typeInfoDetail,
  data,
  customAlertText,
  customTextHeader,
  customTextInfoTitle,
  customTextInfoDetail,
  customButtonShow,
  customButtonText,
  customButtonVariant,
  customButtonClassName,
  customButtonStyle,
  customButtonOnClick,
  cancelButtonText,
  cancelButtonShow = true,
  submitLoading,
  component,
  onCancel,
  onHide,
  onSubmit
}) => {
  return (
    <BSModal
      centered
      show={show}
      size={size}
      onHide={submitLoading ? () => {} : onHide}
      backdropClassName="modal-delete-backdrop"
      className="modal-delete"
    >
      <BSModal.Header closeButton>
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-capitalize`}
          style={titleStyle}
        >
          {title 
            ? title 
            : type === 'custom'
              ? title
              : type === 'error' ? 'Error' : 'Konfirmasi'
          }
        </div>
      </BSModal.Header>
      <BSModal.Body>
        {type === 'info'
          ? <div className="d-flex flex-column justify-content-center align-items-center">
              {typeInfo === 'success'
                ? <IoCheckmarkCircleOutline size={80} className="text-success mb-2" />
                : typeInfo === 'error'
                  ? <IoCloseCircleOutline size={80} className="text-danger mb-2" />
                  : <IoAlertCircleOutline size={80} className="text-info mb-2" />
              }
              <b style={{fontSize: 18}}>
                {typeInfo === 'success'
                  ? customTextInfoTitle ? customTextInfoTitle :`Data berhasil ${typeInfoDetail === 'update' ? 'diubah' : typeInfoDetail === 'delete' ? 'dihapus' : 'disimpan'}!`
                  : typeInfo === 'error'
                    ? customTextInfoTitle ? customTextInfoTitle :`Data gagal ${typeInfoDetail === 'update' ? 'diubah' : typeInfoDetail === 'delete' ? 'dihapus' : 'disimpan'}!`
                    : customTextInfoTitle ? customTextInfoTitle : 'Informasi'
                }
              </b>
              {customTextInfoDetail}
            </div>
          : <>
              {customTextHeader
                ? <b>{customTextHeader}</b>
                : type === 'custom'
                  ? <b>{customTextHeader}</b>
                  : type !== 'error' &&
                      <div className="mb-2">
                        <b>{`Apakah anda yakin ${type === 'create' ? 'menyimpan' : type === 'update' ? 'mengubah' : type === 'delete' ? 'menghapus' : ''} data ini?`}</b>
                      </div>
                }
              <div className="py-1 pb-2">
                {
                  component
                    ? component
                    : data?.map((val, index) => type === 'error'
                        ? <small key={index} className="text-danger d-block">* {val}</small>
                        : <InfoItemVertical 
                            key={index}
                            label={val?.label ?? '-'}
                            text={val?.text ?? '-'}
                          />
                      )
                }
              </div>
              {!!(alert) &&
                <Alert 
                  className="mb-0 p-2"
                  variant={alertVariant}
                >
                  <small>{customAlertText ?? `Data akan dihapus secara permanen dan tidak dapat dikembalikan.`}</small>
                </Alert>
              }
            </>
        }
      </BSModal.Body>
      <BSModal.Footer>
        {cancelButtonShow &&
          <ButtonCancel 
            text={cancelButtonText ? cancelButtonText : type === 'error' || type === 'info' ? 'Tutup' : undefined}
            disabled={submitLoading}
            className='m-1' 
            onClick={onCancel ? onCancel : onHide}
          />
        }
        {type === 'custom' && customButtonShow && <Button text={customButtonText} variant={customButtonVariant} loading={submitLoading} className={`m-1 px-4 ${customButtonClassName}`} style={customButtonStyle} onClick={customButtonOnClick ? customButtonOnClick : onSubmit} />}
        {type === 'create' && <ButtonCreate type="submit" text={customButtonText} className={`m-1 ${customButtonClassName}`} style={customButtonStyle} onClick={onSubmit} loading={submitLoading} />}
        {type === 'update' && <ButtonUpdate type="submit" text={customButtonText} className={`m-1 ${customButtonClassName}`} style={customButtonStyle} onClick={onSubmit} loading={submitLoading} />}
        {type === 'delete' && <ButtonDelete type="submit" text={customButtonText} className={`m-1 ${customButtonClassName}`} style={customButtonStyle} onClick={onSubmit} loading={submitLoading} />}
      </BSModal.Footer>
    </BSModal>
  )
}
