import React from 'react'

export interface ThInterface extends Omit<React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>, 'className' | 'style'> {
  className?: string,
  noPadding?: boolean,
  style?: React.CSSProperties,
  width?: string | number,
  minWidth?: string | number,
  maxWidth?: string | number,
  fontSize?: number | string,
  fixed?: boolean,
  textRight?: boolean, 
  textLeft?: boolean, 
  removeBackground?: boolean
}

export const Th: React.FC<ThInterface> = ({
  children,
  className,
  noPadding,
  style,
  fixed,
  width,
  minWidth,
  maxWidth,
  fontSize = 14,
  textLeft,
  textRight,
  removeBackground,
  ...rest
}) => {
  const thStyle: {} = fixed
    ? {
        ...style,
        position: 'sticky',
        right: -1,
        left: -1,
        top: -1,
        zIndex: 3,
        fontSize: fontSize,
        border: '0.5px solid #dee2e6',
        textAlign: textRight 
          ? 'right' 
          : textLeft
            ? 'left'
            : 'center'
      }
    : {
        ...style,
        position: 'sticky',
        top: -1,
        zIndex: 2,
        fontSize: fontSize,
        border: '0.5px solid #dee2e6',
        textAlign: textRight 
          ? 'right' 
          : textLeft
            ? 'left'
            : 'center'
      }
        
  return (
    <th
      {...rest}
      className={`${noPadding ? 'p-0' : 'p-2'} align-middle ${removeBackground ? 'bg-white' : 'bg-light'} ${className ?? ''}`}
      style={{
        ...thStyle, 
        width: width ? width : '',
        maxWidth: maxWidth ? maxWidth : '',
        minWidth: minWidth ? minWidth : ''
      }}
    >
      {children}
    </th>
  )
}
