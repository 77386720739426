import React from "react"
import { 
  Button, 
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  HiEye
} from 'react-icons/hi'
import {
  ButtonInterface
} from '../Button'

export const ButtonDetail: React.FC<ButtonInterface> = ({
  variant,
  text,
  icon,
  tooltip = true,
  tooltipText,
  tooltipPlacement,
  size = 'sm',
  style,
  ...rest
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText ?? 'Detail data'}</Tooltip> : <div></div>}
    >
      <Button
        {...rest}
        size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
        variant={variant ?? 'info'}
        style={{
          ...style,
          minWidth: icon ? 'auto' : '100px' 
        }}
      >
        <div className="d-flex align-items-center text-white">
          <div>
            {icon && <HiEye className="mb-1"/>}
          </div>
          {Boolean(text) && (
            <span className="ms-1 text-uppercase">
              {text}
            </span>
          )}
        </div>
      </Button>
    </OverlayTrigger>
  )
}