import React from 'react'
import {
  Modal as BSModal,
  ModalProps as BSModalProps
} from 'react-bootstrap'

export interface ModalInterface extends BSModalProps {
  title?: any,
  titleClassName?: string,
  titleStyle?: React.CSSProperties
}

export const Modal: React.FC<ModalInterface> = ({
  show,
  title,
  children,
  closeButton = true,
  titleClassName,
  titleStyle,
  scrollable = true,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      show={show}
      scrollable={scrollable}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-capitalize`}
          style={titleStyle}
        >
          {title}
        </div>
      </BSModal.Header>
      {show 
        ? <>
            {children}
          </>
        : <BSModal.Body>
          </BSModal.Body>
      }
    </BSModal>
  )
}