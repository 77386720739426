import React from 'react'
import { 
  toast,
  ToastContainer,
  ToastContainerProps,
} from 'react-toastify'
import {
  HiExclamationCircle,
  HiCheckCircle,
  HiExclamation,
  HiXCircle
} from 'react-icons/hi'

export interface toastTriggerInterface {
  variant: 'info' | 'success' | 'warning' | 'danger' | 'promise',
  message: string | number | undefined | null
}

export const toastTrigger = ({variant, message}: toastTriggerInterface) => {
  switch (variant) {
    case 'info':
      return toast.info(
        <div className="d-flex align-items-start">
          <div><HiExclamationCircle size={26} className="text-info"/></div> 
          <div className="ps-2 text-info">{message}</div>
        </div>
      )
    case 'success':
      return toast.success(
        <div className="d-flex align-items-start">
          <div><HiCheckCircle size={26} className="text-success"/></div> 
          <div className="ps-2 text-success">{message}</div>
        </div>
      )
    case 'warning':
      return toast.warning(
        <div className="d-flex align-items-start">
          <div><HiExclamation size={26} className="text-warning"/></div> 
          <div className="ps-2 text-warning">{message}</div>
        </div>
      )
    case 'danger':
      return toast.error(
        <div className="d-flex align-items-start">
          <div><HiXCircle size={26} className="text-danger"/></div> 
          <div className="ps-2 text-danger">{message}</div>
        </div>
      )
    default:
      return toast(message)
  }
}

export const toastPromiseTrigger = (
  promise: Promise<unknown>,
  message: {
    loading: any,
    success: any,
    error: any
  }
) => {
  return toast.promise(
    promise,
    {
      pending: {
        render({data}) {
          return message.loading(data)
        },
        icon: true
      },
      success: {
        render({data}) {
          return (
            <div className="d-flex align-items-start">
              <div><HiCheckCircle size={26} className="text-success"/></div> 
              <div className="ps-2 text-success">{message.success(data)}</div>
            </div>
          )
        },
        icon: false,
      },
      error: {
        render({data}) {
          return (
            <div className="d-flex align-items-start">
              <div><HiXCircle size={26} className="text-danger"/></div> 
              <div className="ps-2 text-danger">{message.error(data)}</div>
            </div>
          )
        },
        icon: false
      }
    }
  )
}

export const Toast: React.FC<ToastContainerProps> = ({
  position = "bottom-right",
  ...props
}) => {
  return (
    <ToastContainer 
      {...props}
      icon={false}
      position={position}
    />
  )
}