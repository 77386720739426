import React from 'react'
import {  
  Props,
} from 'react-select'
import {
  AsyncPaginate,
} from 'react-select-async-paginate'
import {
  Form, 
  Spinner
} from 'react-bootstrap'

interface DefaultValueInterface {
  value?: string | number | null,
  label?: string |number | null
}
export interface SelectInterface extends Omit<Props, 'noOptionMessage' | 'defautlValue'> {
  label?: string,
  options: any[],
  error?: boolean,
  errorText?: string,
  errorFetch?: boolean,
  errorFetchText?: boolean,
  noMargin?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  noOptionMessage?: string | number,
  menuFixed?: boolean,
  portalZIndex?: number,
  loading?: boolean,
  loadingText?: string,
  disable?: boolean,
  defaultValue?: DefaultValueInterface
}

export const Select: React.FC<SelectInterface> = ({
  label,
  options,
  noMargin,
  error = false, 
  errorText,
  errorFetch,
  errorFetchText = 'Data gagal dimuat',
  wrapperClassName,
  wrapperStyle,
  placeholder = 'Pilih data',
  noOptionsMessage = 'Tidak ada data',
  menuFixed = false,
  portalZIndex,
  styles,
  loading,
  loadingText = 'Memuat data . . .',
  disable = false,
  defaultValue,
  ...props
}) => {
  const [render, setRender] = React.useState<number | undefined>(undefined)

  const forceRender = () => {
    setTimeout(() => {
      setRender(Math.random() * 10)
    }, 10)
  }
  
  
  React.useEffect(() => {
    forceRender()
    
    return () => {
      setRender(undefined)
    }
  }, [defaultValue?.value, defaultValue?.label, loading, errorFetch])
  
  const loadOptions = async (search: any, prevOptions: any) => {
    let filteredOptions = []

    if (!search) {
      filteredOptions = [...options]
    } else {
      const searchLower = search.toLowerCase()
  
      filteredOptions = options.filter((val: any) => val.label.toLowerCase().includes(searchLower))
    }
  
    const hasMore = filteredOptions.length > prevOptions.length + 25
    const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 25)
  
    return {
      options: slicedOptions,
      hasMore
    }
  }

  return (
    <Form.Group
      as="div"
      className={`${wrapperClassName ?? ''} ${noMargin ? 'm-0' : 'mb-2'}`}
      style={wrapperStyle}
    >
      <Form.Label as="small">{label}</Form.Label>
      <div className="d-flex align-items-center">
        <div style={{width: '100%'}}>
          <AsyncPaginate
            {...props}
            key={render}
            defaultValue={loading || errorFetch ? '' : defaultValue}
            loadOptions={loadOptions}
            placeholder={loading ? loadingText : errorFetch ? errorFetchText : placeholder}
            isDisabled={loading || errorFetch ? true : disable}
            noOptionsMessage={() => noOptionsMessage}
            menuShouldBlockScroll={menuFixed ? true : false}
            menuPosition={menuFixed ? "fixed" : undefined}
            classNamePrefix={error ? "react-select-invalid" : "react-select"}
            styles={{
              ...styles,
              control: (base) => ({
                ...base,
                minHeight: 28,
                maxHeight: 31,
                fontSize: 14,
              }),
              valueContainer: (base) => ({
                ...base,
                paddingTop: 1,
                paddingLeft: 5,
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                flexWrap: 'nowrap',
              }),
              dropdownIndicator: (base) => ({
                ...base,
                padding: 0,
                paddingLeft: 5,
                paddingRight: 5,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: portalZIndex ? portalZIndex : 2000 
              }),
              menu: (base) => ({
                ...base,
                fontSize: 13,
                zIndex: portalZIndex ? portalZIndex : 2000
              }),
            }}
          />
        </div>
        {loading && 
          <Spinner 
            animation="border" 
            size="sm" 
            variant="secondary"
            className="ms-2 ml-2"
          />
          }
      </div>
      <small className="text-danger">{errorText}</small>
    </Form.Group>
  )
}
