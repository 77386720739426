import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  default as ReactSwitch,
  ReactSwitchProps
} from "react-switch"

export interface SwitchInterface extends Omit<ReactSwitchProps, 'checked' | 'onChange'> {
  checked: boolean,
  size?: "sm" | "md" | "lg"
  wrapperClassName?: string,
  tooltip?: boolean,
  tooltipCheckedText?: string,
  tooltipUncheckedText?: string,
  onChange: (checked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>, id: string) => void
}

export const Switch: React.FC<SwitchInterface> = ({ 
  id, 
  size = "md",
  checked, 
  wrapperClassName,
  onChange,
  tooltip,
  tooltipCheckedText,
  tooltipUncheckedText,
  ...rest
}) => {
  const height = size === "sm" ? 26 : size === "lg" ? 30 : 28
  const width = size === "sm" ? 48 : size === "lg" ? 64 : 56

  return (
    <OverlayTrigger
      overlay={tooltip
        ? <Tooltip>
            {checked 
              ? tooltipCheckedText ?? 'Aktif' 
              : tooltipUncheckedText ?? 'Tidak aktif'
            }
          </Tooltip>
        : <div></div>
      }
    >
      <div className={`d-flex align-items-center ${wrapperClassName}`}>
        <ReactSwitch
          {...rest}
          checked={checked}
          onChange={onChange}
          draggable={false}
          onColor="#3B82F6"
          height={height}
          width={width}
        />
      </div>
    </OverlayTrigger>
  );
};
