import React from 'react'
import {
  Spinner
} from 'react-bootstrap'

export const PageLoader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  style,
  ...props
}) => {
  return (
    <div 
      {...props}
      style={{
        ...style,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'white',
        overflow: 'hidden',
      }}
    >
      <Spinner
        animation="border" 
        variant="primary" 
      />
    </div>
  )
}