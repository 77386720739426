import React from 'react'

export interface CheckboxWrapperInterface extends React.HTMLAttributes<HTMLDivElement> {
  label?: string,
  errorText?: string,
  noMargin?: boolean
}

export const CheckboxWrapper: React.FC<CheckboxWrapperInterface> = ({
  label, 
  errorText, 
  children,
  className,
  noMargin,
  ...props
}) => (
  <div 
    {...props}
    className={`${noMargin ? 'm-0' : 'mb-2'} mb-2 d-flex flex-column ${className}`}
  >
    <label>
      <small>
        {label}
      </small>
    </label>
    <div>
      {children}
    </div>
    <small className="mt-1 text-danger">
      {errorText}
    </small>
  </div>
)