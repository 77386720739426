import React from 'react'

export interface TextAreaInterface extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string,
  error?: boolean,
  errorText?: string,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  noMargin?: boolean
}

export const TextArea: React.FC<TextAreaInterface> = ({ 
  label,  
  error, 
  errorText, 
  className,
  wrapperClassName,
  wrapperStyle,
  noMargin, 
  ...rest
}) => {
  return (
    <div className={`${noMargin ? 'm-0' : 'mb-2'} ${wrapperClassName}`} style={wrapperStyle}>
      <small>{label}</small>
      <textarea
        {...rest}
        className={`form-control form-control-sm ${className} ${error && 'is-invalid'}`}
      />
      <div className="invalid-feedback">
        {errorText}
      </div>
    </div>
  )
}

export default TextArea