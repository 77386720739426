import React from 'react'
import {
  Modal as BSModal,
} from 'react-bootstrap'
import {
  HiTrash
} from 'react-icons/hi'
import {
  ModalInterface
} from '../Modal'
import {
  ButtonDelete,
  ButtonCancel
} from '../../Button'

export interface ModalDeleteInterface extends ModalInterface {
  subTitle?: string | number,
  customTitle?: any,
  customSubTitle?: any,
  submitLoading?: boolean,
  customSubmitButtonText?: string | number,
  customCancelButtonText?: string | number,
  onCancel?: () => void,
  onHide?: () => void,
  onSubmit?: () => void,
}

export const ModalDelete: React.FC<ModalDeleteInterface> = ({
  show,
  children,
  closeButton = true,
  title,
  subTitle,
  customTitle,
  customSubTitle,
  titleClassName,
  titleStyle,
  submitLoading,
  scrollable = false,
  customSubmitButtonText,
  customCancelButtonText,
  onHide,
  onCancel,
  onSubmit,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      centered
      show={show}
      backdropClassName="modal-delete-backdrop"
      className="modal-delete"
      scrollable={scrollable}
      onHide={submitLoading ? () => {} : onHide}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-danger text-capitalize`}
          style={titleStyle}
        >
          {customTitle 
            ? customTitle
            : <>
                <HiTrash className="me-2 mr-2" /> Hapus Data {title}
              </>
          }
        </div>
      </BSModal.Header>
      <BSModal.Body className="text-center">
        {show &&
          <>
            {customSubTitle
              ? customSubTitle
              : <>
                  {subTitle
                    ? <h5 className="mb-1">Apakah anda yakin menghapus data {subTitle}?</h5>
                    : <h5 className="mb-1">Apakah anda yakin menghapus data?</h5>
                  }
                </>
            }
            <div>
              {show && children}
            </div>
            <small className="text-danger">Data yang telah dihapus tidak dapat dikembalikan</small>
            <div className="mt-3">
              <ButtonCancel 
                className="m-1"
                disabled={submitLoading}
                text={customCancelButtonText}
                onClick={onCancel ? onCancel : onHide}
              />
              <ButtonDelete
                type="submit"
                text={customSubmitButtonText ?? "Hapus data"}
                className="m-1"
                onClick={onSubmit}
                loading={submitLoading}
              />
            </div>
          </>
        }
      </BSModal.Body>
    </BSModal>
  )
}