
export const dateConvert = () => {

  const getDateConvert = (dateParams: Date) => {
    const day = dateParams.getDay()
    const date = dateParams.getDate()
    const month = dateParams.getMonth()
    const year = dateParams.getFullYear()
    const hours = dateParams.getHours()
    const minute = dateParams.getMinutes()
    const second = dateParams.getSeconds()
    const newHours = hours.toString().length < 2 ? `0${hours}` : hours
    const newMinute = minute.toString().length < 2 ? `0${minute}` : minute
    const newSecond = second.toString().length < 2 ? `0${second}` : second



    const newDate = date.toString().length < 2 ? `0${date}` : date
    const checkMonth = month + 1
    const newMonth = checkMonth.toString().length < 2 ? `0${checkMonth}` : checkMonth

    const dayValue = [
      'Minggu',
      'Senin',
      'Selasa',
      'Rabu',
      'Kamis',
      'Jumat',
      'Sabtu',
    ]

    const monthValue = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ]

    return {
      detailFull: `${dayValue[day]}, ${date} ${monthValue[month]} ${year}`,                                               // => Senin, 1 Januari 2000
      detailFullWithTime: `${dayValue[day]}, ${date} ${monthValue[month]} ${year} ${newHours}:${newMinute}:${newSecond}`, // => Senin, 1 Januari 2000 01:00:00
      detailDay: `${dayValue[day]}`,                                                                                      // => Senin, Selasa, ...
      detailMonth: `${monthValue[month]}`,                                                                                // => Januari, Februari, ...
      default: `${year}-${newMonth}-${newDate}`,                                                                          // => 2000-01-01
      defaultWithTime: `${year}-${newMonth}-${newDate} ${newHours}:${newMinute}:${newSecond}`,                    // => 2000-01-01 01:00:00
      defaultDay: newDate,                                                                                                // => 01, 02, 03, ...
      defaultMonth: newMonth,                                                                                             // => 01, 02, ... 10, 11, 12
      defaultYear: year,                                                                                                  // => 2000, 2001, 2002, ...
      defaultDMY: `${newDate}/${newMonth}/${year}`,                                                                       // => 01/01/2000
      defaultDMYWithTime: `${newDate}/${newMonth}/${year} ${newHours}:${newMinute}:${newSecond}`,                         // => 01/01/2000 01:00:00                                                   
      defaultDashDMY: `${newDate}-${newMonth}-${year}`,                                                                   // => 01-01-2000
      defaultDashDMYWithTime: `${newDate}-${newMonth}-${year} ${newHours}:${newMinute}:${newSecond}`                      // => 01-01-2000 01:00:00
    }
  }

  return {
    getDetailFull: (date: Date) => getDateConvert(date).detailFull,                   // => Senin, 1 Januari 2000
    getDetailFullWithTime: (date: Date) => getDateConvert(date).detailFullWithTime,   // => Senin, 1 Januari 2000 01:00:00
    getDetailDay: (date: Date) => getDateConvert(date).detailDay,                     // => Senin, Selasa, ...
    getDetailMonth: (date: Date) => getDateConvert(date).detailMonth,                 // => Januari, Februari, ...
    getDashYMD: (date: Date) => getDateConvert(date).default,                         // => 2000-01-01
    getDashYMDWithTime: (date: Date) => getDateConvert(date).defaultWithTime,         // => 2000-01-01 01:00:00
    getDashDMY: (date: Date) => getDateConvert(date).defaultDashDMY,                  // => 01-01-2000
    getDashDMYWithTime: (date: Date) => getDateConvert(date).defaultDashDMYWithTime,  // => 01-01-2000 01:00:00
    getSlashDMY: (date: Date) => getDateConvert(date).defaultDMY,                     // => 01/01/2000
    getSlashDMYWithTime: (date: Date) => getDateConvert(date).defaultDMYWithTime,     // => 01/01/2000 01:00:00
    getDay: (date: Date) => getDateConvert(date).defaultMonth,                        // => 01, 02, 03, ...
    getMonth: (date: Date) => getDateConvert(date).defaultMonth,                      // => 01, 02, ... 10, 11, 12
    getYear: (date: Date) => getDateConvert(date).defaultYear,                        // => 2000, 2001, 2002, ...
  }
}