import React from 'react' 

interface TheadInterface extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>, 'className'> {
  className?: string
}

export const Thead: React.FC<TheadInterface> = ({
  children,
  style,
  className,
  ...props
}) => {
  return (
    <thead 
      {...props}
      className={`${className ?? ''}`}
      style={{
        ...style,
        zIndex: 110,
        insetBlockStart: 0
      }}
    >
      {children}
    </thead>
  )
}