import React from 'react'

export interface TdInterface extends Omit<React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>, 'className' | 'style'> {
  className?: string,
  noPadding?: boolean,
  style?: React.CSSProperties,
  width?: number | string,
  maxWidth?: number | string,
  minWidth?: number | string,
  fontSize?: number | string,
  textRight?: boolean,
  textCenter?: boolean,
  fixed?: boolean,
  floatToFixed?: number
}

export const Td: React.FC<TdInterface> = ({ 
  children,
  className,
  noPadding,
  style,
  fontSize = 13,
  width,
  maxWidth,
  minWidth,
  textRight,
  textCenter,
  fixed,
  floatToFixed,
  ...rest
}) => {
  textRight = typeof(children) === 'number' ? true : textRight
  const tdStyle: {} = fixed
  ? {
      ...style,
      position: 'sticky',
      right: -1,
      left: -1,
      zIndex: 2,
      background: fixed ? 'auto' : '',
      border: '0.5px solid #dee2e6',
      fontSize: fontSize ? fontSize : 13,
      textAlign: textRight 
        ? 'right' 
        : textCenter
          ? 'center'
          : 'left'
    }
  : {
      ...style,
      zIndex: 1,
      border: '0.5px solid #dee2e6',
      fontSize: fontSize ? fontSize : 13,
      background: fixed ? 'auto' : '',
      textAlign: textRight 
        ? 'right' 
        : textCenter
          ? 'center'
          : 'left'
      }

  return (
    <td
      {...rest}
      className={`${noPadding ? 'p-0' : 'p-1'} ${className ?? ''}`}
      style={{
        ...tdStyle, 
        width: width ? width : '',
        maxWidth: maxWidth ? maxWidth : '',
        minWidth: minWidth ? minWidth : ''
      }}
    >
      {typeof(children) === 'number'
        ? parseFloat(String(children)).toFixed(floatToFixed ? floatToFixed : 2)
        : children
      }
    </td>
  )
}