import React  from 'react'
import {
  TableProps
} from 'react-bootstrap'

export interface TableInterface extends Omit<TableProps, 'title'> {
  className?: string,
  maxHeight?: string | number,
  title?: string | number
}

export const Table: React.FC<TableInterface> = ({
  title,
  className,
  children,
  style,
  maxHeight,
  ...props
}) => {

  return (
    <>
      {Boolean(title) && 
        <div className="p-1">
          <b>{title}</b>
        </div>
      }
      <div 
        {...props}
        className="rounded"
        style={{
          ...style,
          maxHeight: maxHeight ?? '60vh',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <table
          className={`table table-borderless table-hover bg-white position-relative m-0 ${className ?? ''}`}
          style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0
          }}
          >
          {children}
        </table>
      </div>
    </>
  )
}