import React, {
  useState 
} from 'react'

interface TextCollapseInterface {
  text?: string | number
}

export const TextCollapse: React.FC<TextCollapseInterface> = ({text}) => {
  const [open, setOpen] = useState<boolean>(false)
  const length = text ? String(text).length : 0

  if (length > 250) {
    return (
      <div>
        {open ? text : String(text).slice(0, 250)}
        <b 
          className="text-primary" 
          style={{cursor: 'pointer'}}
          onClick={() => setOpen(!open)}
        >
          {open ? ' Sembunyikan' : ' Selengkapnya . . .'}
        </b>
      </div>
    )
  }

  return (
    <>{text}</>
  )
}