import React from 'react'
import {
  Spinner
} from 'react-bootstrap'

export interface DataStatusInterface extends React.HTMLAttributes<HTMLDivElement>{
  text?: string | number,
  loading?: boolean,
  loadingText?: string
}

export const DataStatus: React.FC<DataStatusInterface> = ({
  text, 
  loading,
  loadingText,
  className,
  ...props
}) => {
  return (
    <div 
      {...props}
      className={`d-flex justify-content-center my-4 ${className ?? ''}`}
    >
      {loading && (
        <Spinner 
          animation="border" 
          size="sm"
          className="me-2 mr-2 mt-1"
        />
      )}
      <h5 className="m-0">
        {loading 
          ? loadingText ? loadingText : 'Memuat data . . .'
          : text
        }
      </h5>
    </div>
  )
}
