import React from 'react'
import {
  InputGroup,
  Form,
  FormControlProps,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap'

export interface InputInterface extends Omit<FormControlProps, 'size'> {
  label?: string,
  name?: string, 
  loading?: boolean,
  loadingText?: string,
  valid?: boolean,
  validText?: string,
  error?: boolean,
  errorText?: string,
  errorFetch?: boolean,
  errorFetchText?: string,
  noMargin?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  tooltip?: boolean,
  tooltipText?: string,
  tooltipPlacement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start',
  size?: 'sm' | 'md' | 'lg' 
}

export const Input: React.FC<InputInterface> = ({
  label,
  name,
  value,
  placeholder,
  loading,
  loadingText = 'Memuat data . . .',
  size = "sm",
  valid = false,
  validText,
  disabled,
  error = false, 
  errorText,
  errorFetch,
  errorFetchText = 'Data gagal dimuat',
  noMargin,
  className,
  wrapperClassName,
  tooltip,
  tooltipPlacement,
  tooltipText,
  ...props
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
    >
      <Form.Group
        as="div"
        className={`${wrapperClassName ?? ''} ${noMargin ? 'm-0' : 'mb-2'}`}
      >
        <Form.Label as="small">{label}</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            {...props}
            name={name}
            value={loading ? '' : errorFetch ? '' : value}
            placeholder={loading ? loadingText : errorFetch ? errorFetchText : placeholder}
            size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
            className={`${className ? className : ''} rounded`}
            isInvalid={error}
            isValid={valid}
            readOnly={disabled 
              ? disabled
              : loading ? true : errorFetch ? true : false
            }
          />
          {loading && 
            <InputGroup.Text style={{background: 'none', border: 'none'}}>
              <Spinner 
                animation="border" 
                size="sm" 
                variant="secondary"
              />
            </InputGroup.Text>
          }
          <Form.Control.Feedback
            type={error ? 'invalid' : 'valid'}
            className="mt-0"
          >
            {validText}
            {errorText}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      </OverlayTrigger>
  )
}