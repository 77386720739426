
export const fetchDropdownMapper = (service: any) => async (url: string, objValue: {[propName: string]: any;}) => {
  const fetch = await service.get(url)
  const mapData = fetch?.data?.data 
      ? fetch.data.data.map((val: any) => {
          let obj:{[propName: string]: any} = {}

          for (const [key, value] of Object.entries(objValue)) {
            obj[key] = val[value]
          }
          
          return obj
        }) 
      : []
      
    return mapData
}