import React from 'react'
import {
  Modal as BSModal,
  ModalBody,
  ModalFooter
} from 'react-bootstrap'
import {
  HiRefresh,
  HiBan,
  HiCheck,
  HiShieldCheck
} from 'react-icons/hi'
import {
  ModalInterface,
  ButtonApprove,
  ButtonVerify,
  ButtonRevision,
  ButtonReject,
  ButtonCancel
} from '../../../components'

export interface ModalApproval extends ModalInterface {
  customTitle?: any,
  type: "verify" | "approve" | "revise" | "reject",
  menuName: string,
  descriptionTitle?: string,
  descriptionText?: any,
  onSubmit?: () => void
}

export const ModalApproval: React.FC<ModalApproval> = ({
  children,
  show,
  type,
  size = "lg",
  menuName,
  closeButton = true,
  title,
  customTitle,
  titleClassName,
  titleStyle,
  descriptionTitle,
  descriptionText,
  scrollable = false,
  onHide,
  onSubmit,
  ...props
}) => {
  const ModalTitle = () => {
    if (type === "approve") {
      return (
        <div className="d-flex align-items-center text-success text-capitalize fw-bold">
          <HiShieldCheck className="me-1 mr-1" />
          Approve {menuName}
        </div>
      )
    }

    if (type === "revise") {
      return (
        <div className="d-flex align-items-center text-warning text-capitalize fw-bold">
          <HiRefresh className="me-1 mr-1" />
          Revise {menuName}
        </div>
      )
    }

    if (type === "reject") {
      return (
        <div className="d-flex align-items-center text-danger text-capitalize fw-bold">
          <HiBan className="me-1 mr-1" />
          Reject {menuName}
        </div>
      )
    }

    return (
      <div className="d-flex align-items-center text-success text-capitalize fw-bold">
        <HiCheck className="me-1 mr-1" />
        Verify {menuName}
      </div>
    )
  }

  const ModalButton: React.FC<{onButtonClick: any}> = ({onButtonClick}) => {
    if (type === "approve") return <ButtonApprove onClick={onButtonClick} />
    if (type === "revise") return  <ButtonRevision onClick={onButtonClick} />
    if (type === "reject") return <ButtonReject onClick={onButtonClick} />

    return <ButtonVerify onClick={onButtonClick} />
  }

  const TypeText = () => {
    if (type === "approve") return <div className="text-success text-uppercase fw-bold">Approve</div>
    if (type === "revise") return  <div className="text-warning text-uppercase fw-bold">Revise</div>
    if (type === "reject") return <div className="text-danger text-uppercase fw-bold">Reject</div>

    return <div className="text-success text-uppercase fw-bold">Verify</div>
  }

  return (
    <BSModal 
      {...props}
      show={show}
      size="lg"
      onHide={onHide}
      scrollable={scrollable}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-primary text-capitalize`}
          style={titleStyle}
        >
          <ModalTitle />
        </div>
      </BSModal.Header>
      <ModalBody>
        {show &&
          <>
            <div className="d-flex">
              <TypeText /> <div className="fw-bold text-capitalize text-uppercase mx-1">{menuName}</div> dengan {descriptionTitle ?? 'catatan:'}
            </div>
            <div 
              className="mt-2 p-2 border rounded" style={{minHeight: 80}} 
              dangerouslySetInnerHTML={{ __html: descriptionText }}
            />
          </>
        }
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={onHide} />
        <ModalButton onButtonClick={onSubmit} />
      </ModalFooter>
    </BSModal>
  )
}