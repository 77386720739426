import React from 'react'
import {
  default as RDatePicker,
  ReactDatePickerProps
} from 'react-datepicker'
import {
  dateConvert
} from '../../utilities'
export interface DatePickerInterface extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
  label?: string,
  size?: "sm" | "md" | "lg",
  selected?: string | undefined | null,
  noMargin?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  error?: boolean,
  errorText?: string,
  onChange?: (callback: string) => void,
  onChangeDefault?: (callback: any) => void
}

export const DatePicker: React.FC<DatePickerInterface> = ({
  label,
  size,
  noMargin,
  selected,
  dateFormat,
  onChange,
  onChangeDefault,
  className,
  wrapperClassName,
  wrapperStyle,
  error,
  errorText,
  ...rest
}) => {
  const checkSelected = selected ? new Date(selected) : null
  const date = dateConvert()

  return (
    <div className={`${noMargin ? 'm-0' : 'mb-2'}  d-flex flex-column ${wrapperClassName}`} style={{ ...wrapperStyle, paddingTop: noMargin ? 0 : '5px' }}>
      <small>{label}</small>
      <RDatePicker
        {...rest}
        dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
        onChange={(val) => {
          return onChangeDefault
            ? onChangeDefault(val)
            : onChange && onChange(date.getDashYMD(val ?? new Date()))
        }}
        selected={checkSelected}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className={`form-control form-control-${size ?? "sm"} ${className} ${error && "is-invalid"}`}
      />
      {error &&
        <small className="text-danger pt-1">{errorText}</small>
      }
    </div>
  )
}