import React, { useContext, useReducer, createContext } from 'react';

interface infoInterface {
  id?: string | number | null;
  username?: string;
  name?: string;
  accessRigths?: string[];
}
interface useAuthValueInterface {
  isAuthenticated: boolean;
  token?: string | null;
  info: infoInterface;
}

interface useAuthDispatchInterface {
  type: 'LOGIN' | 'LOGOUT' | 'CHECK' | 'SET_AUTH_INFO' | 'SET_INFO';
  payload?: useAuthValueInterface;
}

/**
 * authReducerAction:
 * Menangani semua aksi yang diperlukan oleh useReducer pada auth provider
 */
const authReducerAction = (
  prevValue: useAuthValueInterface,
  action: useAuthDispatchInterface
) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOGIN':
      payload?.token && localStorage.setItem('auth-token', payload.token);

      return {
        isAuthenticated: payload?.token ? true : false,
        token: payload?.token ?? null,
        info: {
          id: payload?.info?.id,
          username: payload?.info?.username ?? '',
          name: payload?.info?.name ?? '',
          accessRights: payload?.info?.accessRigths ?? [],
        },
      };

    case 'LOGOUT':
      localStorage.removeItem('auth-token');

      return {
        isAuthenticated: false,
        token: null,
        info: {
          id: '',
          username: '',
          name: '',
          accessRights: [],
        },
      };

    case 'SET_AUTH_INFO':
      return {
        ...prevValue,
        isAuthenticated: payload?.isAuthenticated,
        token: payload?.token ? payload.token : null,
      };

    case 'SET_INFO':
      return {
        ...prevValue,
        info: {
          id: payload?.info?.id,
          username: payload?.info?.username ?? '',
          name: payload?.info?.name ?? '',
          accessRights: payload?.info?.accessRigths ?? [],
        },
      };

    default:
      return prevValue;
  }
};

/**
 * AuthContext:
 * Menginisiasi context untuk autentikasi
 */
const AuthContext = createContext<any>({});

/**
 * AuthProvider:
 * Context Provider yang digunakan untuk wrap seluruh kode sehingga useAuth dapat digunakan secara global
 */
export const AuthProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer<any>(authReducerAction, {
    isAuthenticated: false,
    info: {
      id: '',
      username: '',
      name: '',
      accessRights: [],
    },
  });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * useAuth:
 * Custom hooks yang digunakan untuk menangani otentikasi
 * Dapat digunakan juga untuk mendapatkan info tentang akun yang sedang login
 */
export const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const checkIsAuthenticatedHandler = () => {
    const checkToken = localStorage.getItem('auth-token');

    if (Boolean(checkToken)) {
      return true;
    }

    return false;
  };

  const loginHandler = (token: string | number) =>
    dispatch({ type: 'LOGIN', payload: { token: token } });

  const logoutHandler = () => dispatch({ type: 'LOGOUT' });

  const setInfoHandler = (payload: infoInterface) =>
    dispatch({ type: 'SET_INFO', payload: { info: payload } });

  return {
    isAuthenticated: () => checkIsAuthenticatedHandler(),
    isReadOnly: () => Boolean(state?.info?.accessRights?.includes('SUPA.R')),
    login: (token: string | number) => loginHandler(token),
    logout: () => logoutHandler(),
    getInfo: () => state?.info,
    getId: () => state?.info?.id,
    getUsername: () => state?.info?.username,
    getName: () => state?.info?.name,
    getAccessRights: () => state?.info?.accessRights,
    setInfo: (payload: infoInterface) => setInfoHandler(payload),
  };
};
