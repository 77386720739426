import React, {
  useReducer,
  useContext,
  createContext
} from 'react'
import {
  ModalConfirm,
  ModalConfirmInterface
} from '../../components'

interface modalConfirmReducerActionInterface {
  type: 'TRIGGER' | 'CLOSE' | 'LOADING' | 'REMOVE_LOADING' | 'INFO_SUCCESS' | 'INFO_ERROR' | 'INFO_INFO',
  payload: ModalConfirmInterface
}

const modalConfirmReducerAction = (prevState: ModalConfirmInterface, action: modalConfirmReducerActionInterface): ModalConfirmInterface => {
  const { type, payload  } = action

  switch (type) {
    case 'TRIGGER':
      return {
        ...payload,
        show: true
      }
    
    case 'CLOSE': 
      return {
        ...prevState,
        ...payload,
        show: false
      }

    case 'LOADING':
      return {
        ...prevState,
        ...payload,
        submitLoading: true
      }

    case 'REMOVE_LOADING': 
      return {
        ...prevState,
        ...payload,
        submitLoading: false
      }

    case 'INFO_SUCCESS': 
      return {
        ...prevState,
        ...payload,
        show: true,
        type: 'info',
        typeInfoDetail: prevState.type === 'create' ? 'create' : prevState.type === 'update' ? 'update' : 'delete',
        typeInfo: 'success',
        submitLoading: false
      }

    case 'INFO_ERROR': 
      return {
        ...prevState,
        ...payload,
        show: true,
        type: 'info',
        typeInfoDetail: prevState.type === 'create' ? 'create' : prevState.type === 'update' ? 'update' : 'delete',
        typeInfo: 'error',
        submitLoading: false
      }

    case 'INFO_INFO': 
      return {
        ...prevState,
        ...payload,
        show: true,
        type: 'info',
        typeInfoDetail: undefined,
        typeInfo: 'info',
        submitLoading: false
      }

    default:
      return prevState
  }
}

const ModalConfirmContext = createContext<any>({})

export const ModalConfirmProvider: React.FC<any> = ({children}) => {
  const [state, dispatch] = useReducer(modalConfirmReducerAction, {
    size: undefined,
    title: undefined,
    titleClassName: undefined,
    titleStyle: undefined,
    alert: undefined,
    alertVariant: undefined,
    customTextHeader: undefined,
    customTextInfoTitle: undefined,
    customTextInfoDetail: undefined,
    customAlertText: undefined,
    customButtonShow: undefined,
    customButtonText: undefined,
    customButtonVariant: undefined,
    customButtonClassName: undefined,
    customButtonStyle: undefined,
    customButtonOnClick: undefined,
    type: undefined,
    typeInfo: undefined,
    typeInfoDetail: undefined,
    data: [],
    confirm: undefined,
    show: false,
    cancelButtonShow: true,
    cancelButtonText: undefined,
    submitLoading: false,
    onSubmit: () => {},
    onHide: () => {},
    onCancel: () => {},
  })

  return (
    <ModalConfirmContext.Provider 
      value={{state, dispatch}}
    >
      {children}
      <ModalConfirm 
        size={state.size}
        show={state.show}
        cancelButtonShow={state.cancelButtonShow}
        cancelButtonText={state.cancelButtonText}
        title={state.title}
        titleStyle={state.titleStyle}
        alert={state.alert}
        alertVariant={state.alertVariant}
        customTextHeader={state.customTextHeader}
        customTextInfoTitle={state.customTextInfoTitle}
        customTextInfoDetail={state.customTextInfoDetail}
        customAlertText={state.customAlertText}
        customButtonShow={state.customButtonShow}
        customButtonText={state.customButtonText}
        customButtonVariant={state.customButtonVariant}
        customButtonClassName={state.customButtonClassName}
        customButtonStyle={state.customButtonStyle}
        customButtonOnClick={state.customButtonOnClick}
        type={state.type}
        typeInfo={state.typeInfo}
        typeInfoDetail={state.typeInfoDetail}
        data={state.data}
        component={state.component}
        submitLoading={state.submitLoading}
        onSubmit={state.onSubmit}
        onHide={state.onHide}
        onCancel={state.onCancel}
      />
    </ModalConfirmContext.Provider>
  )
}

export const useModalConfirm = () => {
  const { dispatch } = useContext(ModalConfirmContext)

  return {
    trigger: (value: ModalConfirmInterface) => dispatch({type: 'TRIGGER', payload: value}),
    close: (value?: ModalConfirmInterface) => dispatch({type: 'CLOSE', payload: value}),
    loading: (value?: ModalConfirmInterface) => dispatch({type: 'LOADING', payload: value}),
    removeLoading: (value?: ModalConfirmInterface) => dispatch({type: 'REMOVE_LOADING', payload: value}),
    infoSuccess: (value?: ModalConfirmInterface) => dispatch({type: 'INFO_SUCCESS', payload: value}),
    infoError: (value?: ModalConfirmInterface) => dispatch({type: 'INFO_ERROR', payload: value}),
    infoInfo: (value?: ModalConfirmInterface) => dispatch({type: 'INFO_INFO', payload: value})
  }
}