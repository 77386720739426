
export const rupiahConvert = () => {
  const getWithComa = (number: any) => {
    const convertToNumber = parseFloat(number)
    const checkConvert = convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber))

    return new Intl.NumberFormat('id-ID', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(checkConvert)
  }

  const getFromWithComaToFloat = (number: any) => {
    const removeStringAndDotThenSplitComaIntoArray = String(number).replace(/[^\d|,-]/g,'').split(',')
    return removeStringAndDotThenSplitComaIntoArray.join('.')
  }

  return {
    getFromWithComaToFloat: (number: any) => getFromWithComaToFloat(number),
    getWithComa: (number: any) => `Rp${getWithComa(number)}`,
    getWithComaWithoutLabel: (number: any) => getWithComa(number)
  }
}