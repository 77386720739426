import React from "react"
import { 
  Button, 
  OverlayTrigger,
  Tooltip,
  Spinner
} from "react-bootstrap"
import {
  HiPlus
} from 'react-icons/hi'
import {
  ButtonInterface
} from '../Button'

export interface ButtonCreateInterface extends Omit<ButtonInterface, 'size'> {
  noText?: boolean,
  size?: 'sm' | 'md' | 'lg',
  icon?: boolean
}

export const ButtonCreate: React.FC <ButtonCreateInterface> = ({
  text,
  noText = false,
  variant,
  icon = false,
  tooltip = true,
  tooltipText,
  tooltipPlacement,
  size = 'sm',
  loading,
  loadingText,
  disabled,
  style,
  ...rest
}) => {
  const checkIsVariantOutline = (variant: String) => Boolean(variant.substring(0,7) === 'outline')

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText ?? 'Tambah data'}</Tooltip> : <div></div>}
    >
      <Button
        {...rest}
        size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
        variant={variant ?? "primary"}
        disabled={loading ? true : disabled ? true : false}
        style={{
          ...style,
          minWidth: icon ? 'auto' : '100px' 
        }}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <Spinner 
                animation="border" 
                variant={checkIsVariantOutline(variant ?? 'primary') ? variant : 'white'}
                size="sm" 
              />
              <div className={`ms-2 ml-2 ${variant === 'warning' || variant === "info" ? 'text-white' : ''}`}>
                {loadingText ?? "Memproses . . ."
              }</div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            {icon &&
              <div>
                <HiPlus className="mb-1" />
              </div>
            }
            {!noText && (
              <span className="mx-1 text-uppercase">
                {text  ? text : 'Tambah'}
              </span>
            )}
          </div>
        )}
      </Button>
    </OverlayTrigger>
  )
}