import React from 'react';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Th,
  Td,
  TableInterface,
  ThInterface,
  TdInterface,
  Pagination,
  DataStatus,
} from '../../../components';
import { tableNumber } from '../../../utilities';

interface RowPropsInterface
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {}

export interface TableHeaderInterface {
  text: string | number;
  field: string | number;
  disableSort?: boolean;
  props?: ThInterface;
}

export interface TableSortConfigInterface {
  field: string | number;
  order?: 'asc' | 'desc';
}

export interface TableBodyIterface {
  field?: string | number;
  props?: TdInterface;
  customField?: (data?: any, index?: any) => void;
  propsCallback?: (data?: any, index?: any) => TdInterface;
}
export interface TableFooterIterface {
  text?: string | number;
  props?: ThInterface;
}

export interface TableListInterface extends TableInterface {
  data: Array<any>;
  tableHeader: Array<TableHeaderInterface>;
  tableBody: Array<TableBodyIterface>;
  tableFooter?: Array<TableFooterIterface>;
  multipleTableHeader?: Array<TableHeaderInterface>[];
  multipleTableFooter?: Array<TableFooterIterface>[];
  loading?: boolean;
  loadingText?: string;
  error?: boolean;
  errorText: string;
  pagination?: boolean;
  dataTotal?: number;
  dataLength?: number;
  pageCurrent?: number;
  pageTotal?: number;
  maxheight?: string | number;
  removeNo?: boolean;
  sortable?: boolean;
  sortableAsync?: boolean;
  defaultSort?: TableSortConfigInterface;
  onSortChange?: (sort: TableSortConfigInterface) => void;
  rowProps?: (data?: any, index?: any) => RowPropsInterface;
  customRow?: (data?: any, index?: any, tableNumber?: any) => React.ReactNode;
  onPaginationChange?: (value: any) => void;
  onDataLengthChange?: (value: any) => void;
}

export const TableList: React.FC<TableListInterface> = ({
  data,
  tableHeader,
  tableBody,
  tableFooter,
  multipleTableHeader,
  multipleTableFooter,
  loading,
  loadingText,
  error,
  errorText,
  pagination,
  dataTotal = 1,
  dataLength = 1,
  pageCurrent = 1,
  pageTotal = 1,
  maxHeight,
  removeNo = false,
  sortable,
  sortableAsync,
  defaultSort,
  onSortChange,
  rowProps,
  customRow,
  onPaginationChange,
  onDataLengthChange,
  ...props
}) => {
  const [localSortConfig, setLocalSortConfig] =
    React.useState<TableSortConfigInterface>({
      field: '',
      order: 'desc',
    });

  const getLengthMultipleTableHeader = (): number => {
    const getLength = multipleTableHeader
      ? multipleTableHeader.map((val) => val.length)
      : [1];

    return Math.max(...getLength);
  };

  const sortedData = React.useMemo(() => {
    if (sortable && !sortableAsync && localSortConfig?.field) {
      return data.sort((el1, el2) => {
        const element1 = isNaN(el1?.[localSortConfig?.field ?? ''])
          ? el1?.[localSortConfig?.field ?? '']?.toString()
          : parseFloat(el1?.[localSortConfig?.field ?? '']);
        const element2 = isNaN(el2?.[localSortConfig?.field ?? ''])
          ? el2?.[localSortConfig?.field ?? '']?.toString()
          : parseFloat(el2?.[localSortConfig?.field ?? '']);

        if (element1 > element2) {
          return localSortConfig.order === 'desc' ? -1 : 1;
        }

        if (element1 < element2) {
          return localSortConfig.order === 'desc' ? 1 : -1;
        }

        return 0;
      });
    }

    return data;
  }, [localSortConfig?.field, localSortConfig?.order, JSON.stringify(data)]);

  React.useEffect(() => {
    setLocalSortConfig({
      field: defaultSort?.field ?? '',
      order: defaultSort?.order ?? 'asc',
    });
  }, []);

  return (
    <>
      <Table
        {...props}
        maxHeight={
          maxHeight
            ? maxHeight
            : tableFooter || multipleTableFooter
            ? '75vh'
            : undefined
        }
      >
        <Thead className="position-sticky">
          {multipleTableHeader ? (
            multipleTableHeader?.map((table, tableIndex) => (
              <tr key={tableIndex}>
                {table.map((val, index) => {
                  if (multipleTableHeader.length - 1 === tableIndex) {
                    return (
                      <Th
                        {...val.props}
                        key={index}
                        fixed={
                          data && data.length > 0
                            ? Boolean(val?.props?.fixed)
                            : false
                        }
                        style={
                          sortable
                            ? val?.disableSort
                              ? val?.props?.style ?? undefined
                              : { cursor: 'pointer' }
                            : val?.props?.style ?? undefined
                        }
                        onClick={
                          sortable
                            ? val?.disableSort
                              ? val.props?.onClick
                              : () => {
                                  onSortChange &&
                                    onSortChange({
                                      field: val.field,
                                      order:
                                        localSortConfig?.order === 'desc'
                                          ? 'asc'
                                          : 'desc',
                                    });
                                  setLocalSortConfig({
                                    field: val.field,
                                    order:
                                      localSortConfig?.order === 'desc'
                                        ? 'asc'
                                        : 'desc',
                                  });
                                }
                            : val.props?.onClick
                        }
                      >
                        {sortable ? (
                          val.disableSort ? (
                            val.text
                          ) : localSortConfig?.field === val.field ? (
                            <>
                              <span style={{ marginRight: '20px' }}>
                                {val.text}
                              </span>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '20%',
                                  right: 5,
                                  width: '20px',
                                }}
                              >
                                {localSortConfig?.order === 'desc' ? (
                                  <HiArrowDown />
                                ) : (
                                  <HiArrowUp />
                                )}
                              </div>
                            </>
                          ) : (
                            val.text
                          )
                        ) : (
                          val.text
                        )}
                      </Th>
                    );
                  }

                  return (
                    <Th
                      {...val.props}
                      key={index}
                      fixed={
                        data && data.length > 0
                          ? Boolean(val?.props?.fixed)
                          : false
                      }
                    >
                      {val.text}
                    </Th>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              {!removeNo && <Th width={25}>No</Th>}
              {tableHeader.map((val: TableHeaderInterface, index: number) => (
                <Th
                  {...val.props}
                  key={index}
                  fixed={
                    data && data.length > 0 ? Boolean(val?.props?.fixed) : false
                  }
                  style={
                    sortable
                      ? val?.disableSort
                        ? val?.props?.style ?? undefined
                        : { cursor: 'pointer' }
                      : val?.props?.style ?? undefined
                  }
                  onClick={
                    sortable
                      ? val?.disableSort
                        ? val.props?.onClick
                        : () => {
                            onSortChange &&
                              onSortChange({
                                field: val.field,
                                order:
                                  localSortConfig?.order === 'desc'
                                    ? 'asc'
                                    : 'desc',
                              });
                            setLocalSortConfig({
                              field: val.field,
                              order:
                                localSortConfig?.order === 'desc'
                                  ? 'asc'
                                  : 'desc',
                            });
                          }
                      : val.props?.onClick
                  }
                >
                  {sortable ? (
                    val.disableSort ? (
                      val.text
                    ) : localSortConfig?.field === val.field ? (
                      <>
                        <span style={{ marginRight: '20px' }}>{val.text}</span>
                        <div
                          style={{
                            position: 'absolute',
                            top: '20%',
                            right: 5,
                            width: '20px',
                          }}
                        >
                          {localSortConfig?.order === 'desc' ? (
                            <HiArrowUp />
                          ) : (
                            <HiArrowDown />
                          )}
                        </div>
                      </>
                    ) : (
                      val.text
                    )
                  ) : (
                    val.text
                  )}
                </Th>
              ))}
            </tr>
          )}
        </Thead>
        <Tbody>
          {!loading && sortedData && sortedData.length > 0 ? (
            sortedData.map((val: any, index: number) =>
              customRow &&
              customRow(
                val,
                index,
                tableNumber({
                  dataLength: dataLength,
                  pageCurrent: pageCurrent,
                  index: index,
                })
              ) ? (
                customRow(
                  val,
                  index,
                  tableNumber({
                    dataLength: dataLength,
                    pageCurrent: pageCurrent,
                    index: index,
                  })
                )
              ) : (
                <tr key={index} {...(rowProps ? rowProps(val, index) : null)}>
                  {!removeNo && (
                    <Td textCenter>
                      {pagination
                        ? tableNumber({
                            dataLength: dataLength,
                            pageCurrent: pageCurrent,
                            index: index,
                          })
                        : String(index + 1)}
                    </Td>
                  )}
                  {tableBody.map((td: TableBodyIterface, indexTd: number) => (
                    <Td
                      {...td.props}
                      {...(td.propsCallback
                        ? td.propsCallback(val, index)
                        : null)}
                      key={indexTd}
                      className={
                        td.props?.fixed
                          ? `${td.props?.className} ${
                              td.propsCallback
                                ? td.propsCallback(val, index)?.className
                                : ''
                            } ${
                              rowProps
                                ? rowProps(val, index).className
                                : 'bg-white'
                            } `
                          : `${td.props?.className} ${
                              td.propsCallback
                                ? td.propsCallback(val, index)?.className
                                : ''
                            }`
                      }
                    >
                      {td.customField
                        ? td.customField(val, index)
                        : td?.field
                        ? val[td?.field]
                          ? val[td.field]
                          : '-'
                        : '-'}
                    </Td>
                  ))}
                </tr>
              )
            )
          ) : (
            <tr>
              <Td
                colSpan={
                  multipleTableHeader
                    ? getLengthMultipleTableHeader()
                    : tableHeader.length + 1
                }
              >
                <DataStatus
                  loading={loading}
                  text={
                    loading
                      ? loadingText
                        ? loadingText
                        : ''
                      : error
                      ? errorText
                        ? errorText
                        : 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </Td>
            </tr>
          )}
        </Tbody>
        {!!(tableFooter || multipleTableFooter) && (
          <Tfoot className="position-sticky">
            {multipleTableFooter ? (
              multipleTableFooter.map((table, tableIndex) => (
                <tr key={tableIndex}>
                  {table.map((val: TableFooterIterface, index: number) => (
                    <Th
                      {...val.props}
                      key={index}
                      removeBackground={tableIndex % 2 !== 0}
                    >
                      {val.text}
                    </Th>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="bg-light">
                {tableFooter &&
                  tableFooter.map((val: TableFooterIterface, index: number) => (
                    <Th
                      {...val.props}
                      key={index}
                      fixed={
                        data && data.length > 0
                          ? Boolean(val?.props?.fixed)
                          : false
                      }
                    >
                      {val.text}
                    </Th>
                  ))}
              </tr>
            )}
          </Tfoot>
        )}
      </Table>

      {Boolean(pagination && !loading && data && data.length > 0) && (
        <Pagination
          pageCurrent={pageCurrent}
          pageTotal={pageTotal}
          dataTotal={dataTotal}
          dataLength={dataLength}
          onDataLengthChange={(val: any) =>
            onDataLengthChange && onDataLengthChange(val)
          }
          onPaginationChange={(val: any) =>
            onPaginationChange && onPaginationChange(val)
          }
        />
      )}
    </>
  );
};
