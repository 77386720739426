import React from "react"
import { 
  Button, 
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  HiBan
} from 'react-icons/hi'
import {
  ButtonCreateInterface
} from '../ButtonCreate'

export const ButtonReject: React.FC<ButtonCreateInterface> = ({
  text,
  icon,
  noText = false,
  variant,
  tooltip = true,
  tooltipText,
  tooltipPlacement,
  size = "sm",
  style,
  ...rest
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText ?? 'Reject data'}</Tooltip> : <div></div>}
    >
      <Button
        {...rest}
        size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
        variant={variant ?? "danger"}
        style={{
          ...style,
          minWidth: icon ? 'auto' : '100px' 
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            {icon && <HiBan className="mb-1" />}
          </div>
          {!noText && (
            <span className="mx-1 text-uppercase">
              {text  ? text : 'Reject'}
            </span>
          )}
        </div>
      </Button>
    </OverlayTrigger>
  )
}