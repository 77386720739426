import React from 'react';
import { Row, Col, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { FiFileText } from 'react-icons/fi';
import { ContentLayout, ContentLayoutInterface } from '../ContentLayout';
import {
  InputSearch,
  ButtonCreate,
  ButtonBack,
  TableList,
  TableHeaderInterface,
  TableBodyIterface,
  TableFooterIterface,
  TableSortConfigInterface,
  ButtonExport,
} from '../../components';
interface ListLayoutInterface extends ContentLayoutInterface {
  maxTableHeight?: string | number;
  removeNo: boolean;
  tableTitle?: string | number;
  tableHeader: Array<TableHeaderInterface>;
  tableBody: Array<TableBodyIterface>;
  tableFooter: Array<TableFooterIterface>;
  multipleTableHeader?: Array<TableHeaderInterface>[];
  multipleTableFooter?: Array<TableFooterIterface>[];
  data: Array<any>;
  dataTotal: number;
  dataLength: number;
  pageCurrent: number;
  pageTotal: number;
  showSearch?: boolean;
  showExportExcelButton?: boolean;
  showExportPdfButton?: boolean;
  rightTopButtonType?: 'create' | 'back';
  error?: boolean;
  errorText?: string | number;
  loading?: boolean;
  loadingText?: string | number;
  loadingExportButton?: boolean;
  loadingExportButtonText?: string | number;
  pagination?: boolean;
  sortable?: boolean;
  sortableAsync?: boolean;
  defaultSort?: TableSortConfigInterface;
  onSortChange?: (sort: TableSortConfigInterface) => void;
  rowProps?: (
    data?: any,
    index?: any
  ) => React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >;
  customRow?: (data?: any, index?: any, tableNumber?: any) => React.ReactNode;
  customLeftTopSection?: () => React.ReactNode;
  customRightTopSection?: () => React.ReactNode;
  onSearchChange?: (value: any) => void;
  onPaginationChange?: (value: any) => void;
  onDataLengthChange?: (value: any) => void;
  onCreateButtonClick?: () => void;
  onBackButtonClick?: () => void;
  onExportExcelButtonClick?: () => void;
  onExportPdfButtonClick?: () => void;
}

export const ListLayout: React.FC<ListLayoutInterface> = ({
  removeNo = false,
  maxTableHeight,
  data,
  tableTitle,
  tableHeader,
  tableBody,
  tableFooter,
  multipleTableHeader,
  multipleTableFooter,
  dataLength,
  dataTotal,
  pageCurrent,
  pageTotal,
  showSearch = true,
  showExportExcelButton = false,
  showExportPdfButton = false,
  rightTopButtonType = 'create',
  loading,
  loadingText,
  loadingExportButton,
  loadingExportButtonText = 'Memproses . . .',
  error,
  errorText,
  pagination = true,
  showBreadcrumb = true,
  sortable,
  sortableAsync,
  defaultSort,
  onSortChange,
  rowProps,
  customRow,
  onSearchChange,
  customLeftTopSection,
  customRightTopSection,
  onPaginationChange,
  onDataLengthChange,
  onCreateButtonClick,
  onBackButtonClick,
  onExportExcelButtonClick,
  onExportPdfButtonClick,
  ...props
}) => {
  return (
    <ContentLayout {...props} showBreadcrumb={showBreadcrumb}>
      <Row className="mb-3 mb-md-1">
        <Col className="mb-3 mb-sm-2 mb-md-0">
          {customRightTopSection ? (
            customRightTopSection()
          ) : (
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
              {showExportExcelButton || showExportPdfButton ? (
                <Dropdown align="end">
                  <Dropdown.Toggle
                    size="sm"
                    variant="outline-success"
                    className="mx-2"
                    disabled={loadingExportButton}
                  >
                    {loadingExportButton ? (
                      <Spinner size="sm" className="me-1" animation="border" />
                    ) : (
                      <FiFileText className="mb-1 mr-3" />
                    )}
                    <span className="mx-1">
                      {loadingExportButton ? loadingExportButtonText : 'EXPORT'}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mt-2">
                    {showExportExcelButton && (
                      <Dropdown.Item onClick={onExportExcelButtonClick}>
                        Export Excel
                      </Dropdown.Item>
                    )}
                    {showExportPdfButton && (
                      <Dropdown.Item onClick={onExportPdfButtonClick}>
                        Export PDF
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
              {rightTopButtonType === 'back' ? (
                <ButtonBack
                  tooltip={false}
                  className="me-2"
                  style={{ width: 'fit-content' }}
                  onClick={onBackButtonClick}
                />
              ) : (
                <ButtonCreate
                  icon
                  tooltip={false}
                  className="mt-sm-0"
                  style={{ width: 'fit-content' }}
                  onClick={onCreateButtonClick}
                />
              )}
            </div>
          )}
        </Col>
        <Col
          md={{ span: 4, order: 'first' }}
          lg={{ span: customLeftTopSection ? 6 : 3 }}
        >
          {customLeftTopSection
            ? customLeftTopSection()
            : showSearch && (
                <InputSearch
                  className="mb-2 mb-sm-3"
                  onChange={onSearchChange}
                />
              )}
        </Col>
      </Row>
      <TableList
        title={tableTitle}
        sortable={sortable}
        sortableAsync={sortableAsync}
        defaultSort={defaultSort}
        onSortChange={onSortChange}
        removeNo={removeNo}
        maxHeight={maxTableHeight}
        rowProps={rowProps}
        customRow={customRow}
        pagination={pagination}
        loading={loading}
        tableHeader={tableHeader}
        tableBody={tableBody}
        tableFooter={tableFooter}
        multipleTableHeader={multipleTableHeader}
        multipleTableFooter={multipleTableFooter}
        data={data}
        errorText="Data gagal dimuat"
        pageCurrent={pageCurrent}
        pageTotal={pageTotal}
        dataTotal={dataTotal}
        dataLength={dataLength}
        onDataLengthChange={onDataLengthChange}
        onPaginationChange={onPaginationChange}
      />
    </ContentLayout>
  );
};
