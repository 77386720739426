import React from 'react'
import {
  Modal as BSModal,
} from 'react-bootstrap'
import {
  HiPencilAlt
} from 'react-icons/hi'
import {
  ModalCreateInterface
} from '../ModalCreate'
import { 
  ButtonUpdate,
  ButtonCancel
} from '../../Button'

export const ModalUpdate: React.FC<ModalCreateInterface> = ({
  show,
  children,
  closeButton = true,
  title,
  customTitle,
  titleClassName,
  titleStyle,
  submitLoading,
  scrollable = false,
  customSubmitButtonText,
  customCancelButtonText,
  onCancel,
  onHide,
  onSubmit,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      show={show}
      scrollable={scrollable}
      onHide={submitLoading ? () => {} : onHide}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-warning text-capitalize`}
          style={titleStyle}
        >
          {customTitle 
            ? customTitle
            : <>
                <HiPencilAlt className="me-2 mr-2" /> Ubah Data {title}
              </>
          }
        </div>
      </BSModal.Header>
      <BSModal.Body>
        {show
          ? <>{children}</>
          : <></>
        }
      </BSModal.Body>
      <BSModal.Footer>
        <ButtonCancel 
          text={customCancelButtonText}
          disabled={submitLoading}
          onClick={onCancel ? onCancel : onHide} 
        />
        <ButtonUpdate
          type="submit"
          text={customSubmitButtonText ?? 'Ubah'}
          loading={submitLoading}
          onClick={onSubmit}
          className="px-3"
        />
      </BSModal.Footer>
    </BSModal>
  )
}