import React from 'react'
import {
  InputGroup,
  Form,
  FormControlProps,
  OverlayTrigger,
  Spinner,
  Tooltip,
  Image
} from 'react-bootstrap'
import { 
  ButtonDetail
} from '../../../../components'

interface DataInterface {
  data: any, 
  name: string, 
  link: string
}
interface InputFileSingleInterface extends Omit<FormControlProps, 'size' | 'onChange'> {
  label?: string,
  name?: string,
  data?: any,
  link?: string,
  loading?: boolean,
  loadingText?: string,
  valid?: boolean,
  validText?: string,
  error?: boolean,
  errorText?: string,
  errorFetch?: boolean,
  errorFetchText?: string,
  noMargin?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  tooltip?: boolean,
  tooltipText?: string,
  tooltipPlacement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start',
  size?: 'sm' | 'md' | 'lg' ,
  acceptFileType?: (string)[],
  preview?: boolean,
  previewCustomButtonText?: string | number,
  onChange?: (val: DataInterface) => void
}

export const InputFileSingle: React.FC<InputFileSingleInterface> = ({
  label,
  name,
  link,
  data,
  value,
  placeholder,
  loading,
  loadingText = 'Memuat data . . .',
  size = "sm",
  valid = false,
  validText,
  disabled,
  error = false, 
  errorText,
  errorFetch,
  errorFetchText = 'Data gagal dimuat',
  noMargin,
  className,
  wrapperClassName,
  tooltip,
  tooltipPlacement,
  tooltipText,
  acceptFileType = ['pdf', 'xlsx', 'dwg', 'docx', 'skp', 'zip', 'rar'],
  preview = true,
  previewCustomButtonText,
  onChange,
  ...props
}) => {

  // Menangani saat file dipilih
  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    // Check apakah ada file yang dipilih
    if (!file) return null 
  
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const generalFileType = file.name.split('.').pop()

    // Check jika type file sudah benar
    if (acceptFileType.includes(generalFileType ? generalFileType : '')) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)
      const finalValue = {data: file, name: generateName, link: generateLink}
      
      return onChange && onChange(finalValue)
    } else {
      e.target.value = ''
      return window.alert(`Format file tidak valid, harap masukan file berformat ${acceptFileType.join(', ')}`)
    }
  }

  return (
    <>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
      >
        <Form.Group
          as="div"
          className={`${wrapperClassName ?? ''} ${noMargin ? 'm-0' : 'mb-2'}`}
        >
          <Form.Label as="small">{label}</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              {...props}
              type="file"
              value={loading ? '' : errorFetch ? '' : value}
              placeholder={loading ? loadingText : errorFetch ? errorFetchText : placeholder}
              size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
              className={`${className ? className : ''} rounded`}
              isInvalid={error}
              isValid={valid}
              readOnly={disabled 
                ? disabled
                : loading ? true : errorFetch ? true : false
              }
              onChange={onChangeFileHandler}
            />
            {loading && 
              <InputGroup.Text style={{background: 'none', border: 'none'}}>
                <Spinner 
                  animation="border" 
                  size="sm" 
                  variant="secondary"
                />
              </InputGroup.Text>
            }
            <Form.Control.Feedback
              type={error ? 'invalid' : 'valid'}
              className="mt-0"
            >
              {validText}
              {errorText}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        </OverlayTrigger>
        {link && preview
          ? <ButtonDetail 
              icon
              tooltip={false}
              text={previewCustomButtonText ? previewCustomButtonText : "Lihat File"}
              onClick={() => window.open(link, '_blank')}
              className="mb-2"
              variant="primary"
            />
          : ''
        }
      </>
  )
}