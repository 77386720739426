import React from 'react'
import {
  Modal as BSModal,
} from 'react-bootstrap'
import {
  HiAdjustments,
} from 'react-icons/hi'
import {
  ModalInterface
} from '../Modal'
import { 
  ButtonCancel, 
  Button
} from '../../Button'

interface ModalFilterInterface extends ModalInterface {
  customTitle?: any,
  customSubmitButtonText?: string | number,
  customResetlButtonText?: string | number,
  submitLoading?: boolean,
  onSubmit?: () => void,
  onReset?: () => void
}

export const ModalFilter: React.FC<ModalFilterInterface> = ({
  show,
  children,
  closeButton = true,
  title,
  customTitle,
  titleClassName,
  titleStyle,
  submitLoading,
  scrollable = false,
  customSubmitButtonText,
  customResetlButtonText,
  onHide,
  onReset,
  onSubmit,
  ...props
}) => {
  return (
    <BSModal 
      {...props}
      show={show}
      onHide={submitLoading ? () => {} : onHide}
      scrollable={scrollable}
    >
      <BSModal.Header 
        closeButton={closeButton}
      >
        <div 
          className={`${titleClassName} fw-bold d-flex align-items-center text-primary text-capitalize`}
          style={titleStyle}
        >
          {customTitle 
            ? customTitle
            : <>
                <HiAdjustments className="me-2 mr-2" /> Filter Data {title}
              </>
          }
        </div>
      </BSModal.Header>
      <BSModal.Body>
        {show
          ? <>{children}</>
          : <></>
        }
      </BSModal.Body>
      <BSModal.Footer>
        <ButtonCancel 
          text={customResetlButtonText ? customResetlButtonText : 'RESET'}
          disabled={submitLoading}
          onClick={onReset ? onReset : onHide} 
        />
        <Button 
          type="submit"
          text={customSubmitButtonText ? customSubmitButtonText : 'FILTER'}
          loading={submitLoading}
          onClick={onSubmit}
          className="px-4"
        />
      </BSModal.Footer>
    </BSModal>
  )
}