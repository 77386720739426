import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { 
  HiPhotograph
} from 'react-icons/hi'
import ImageViever from 'react-simple-image-viewer'
import FileSaver from 'file-saver'
import {
  ButtonDelete,
  ButtonDownload,
  ModalDelete,
  ButtonCreate
} from '../../../../components'

interface DataInterface {
  data: any, 
  name: string, 
  link: string
}

interface ModalDeleteConfigInterface {
  index: number,
  show: boolean,
  loading: boolean,
  data: string,
}
interface ViewImageConfigInterace {
  index: number,
  show: boolean,
}

export interface InputImageInterface {
  type: 'action' | 'view',
  data: (DataInterface)[],
  acceptFileType?: (string)[],
  createButtonPosition?: 'start' | 'center' | 'end',
  createButtonText?: string | number,
  showCreateButton?: boolean,
  setData: (data: (DataInterface)[]) => void
}

export const InputImageMultiple: React.FC<InputImageInterface> = ({
  type: TYPE = 'action', 
  data,
  showCreateButton = true, 
  createButtonPosition = 'end',
  createButtonText = 'Tambah Gambar',
  acceptFileType = ['jpg', 'png', 'gif', 'jpeg'],
  setData,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [modalDeleteConfig, setModalDeleteConfig] = useState<ModalDeleteConfigInterface>({
    index: 0,
    show: false,
    loading: false,
    data: ''
  })
  const [viewImageConfig, setViewImageConfig] = useState<ViewImageConfigInterace>({
    show: false,
    index: 0
  })

  const filterImageHandler = data.map((val, index) => ({...val, index: index})).filter((val, index) => {
    const file = val.name ? val.name : val.link
    const getFileType = file?.split('.') ? String(file?.split('.').pop()) : ''
    
    // Check jika type file sudah benar
    if (acceptFileType.includes(getFileType)) {    
      return {
        ...val,
        index: index
      }
    } else {
      return false
    }
  })

  // Filter data gambar agar array hanya terisi link gambar (untuk ImageViewer)
  const filterLinkHandler = useMemo(() => {
    return data.map(val => val.link)
  }, [data])

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback((index) => {
    setViewImageConfig({
      show: true,
      index: index
    })
  }, [viewImageConfig])

  // Trigger input file agar terclick
  const onClickFilesHandler = () => Boolean(TYPE !== 'view') && Boolean(inputFileRef?.current ? inputFileRef.current.click() : false)

  // Menangani saat file dipilih
  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    
    // Check apakah ada file yang dipilih
    if (!file) return null 
  
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1] 

    // Check jika type file sudah benar
    if (acceptFileType.includes(getFileType)) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)
      const finalValue = {data: file, name: generateName, link: generateLink}
      
      return setData([...data, finalValue])
    } else {
      return window.alert(`Format file tidak valid, harap masukan file berformat ${acceptFileType.join(', ')}`)
    }
  }

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({...modalDeleteConfig, loading: true})
    const filterData = data.filter((_val, index) => index !== modalDeleteConfig.index)

    setTimeout(() => {
      setData(filterData)
      setModalDeleteConfig({
        index: 0,
        show: false,
        loading: false,
        data: ''
      })
    }, 300)
  },[modalDeleteConfig])

  // Menampilkan komponen foto
  const Image = (params: {imageText?: string, imageUrl?: string, onView: (e: any) => void, onDownload: (e: any) => void, onDelete: (e: any) => void}) => {
    const [isHover, setIsHover] = useState(false)
    const {imageText, imageUrl, onView, onDownload, onDelete} = params

    return (
      <OverlayTrigger
        flip={true}
        overlay={<Tooltip style={{zIndex: 1}}>{imageText}</Tooltip>}
      >
        <Col 
          sm={12} 
          md={6} 
          lg={4} 
          xl={3}
          className="my-3 position-relative"
          style={{cursor: 'zoom-in', objectFit: 'cover'}}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Download Button */}
          <ButtonDownload
            noText
            size="sm"
            style={{position: 'absolute', zIndex: 1, right: 20}}
            className="m-2 shadow"
            onClick={onDownload}
          />

          {/* Delete Button */}
          {TYPE !== 'view' && 
            <ButtonDelete
              noText
              icon
              size="sm"
              variant="danger"
              style={{position: 'absolute', zIndex: 1, top: 35, right: 20}}
              className="m-2 shadow"
              onClick={onDelete}
            />
          }
          <img 
            className={`img-fluid rounded border ${isHover ? 'shadow' : 'shadow-sm'}`}
            style={{height: 200, width: '100%', objectFit: 'cover', transform: isHover ? 'scale(1.03)' : 'none', transition: 'all 0.1s ease-in-out'}}
            src={imageUrl} 
          />
        </Col>
      </OverlayTrigger>
    )
  }

  return (
    <div>
      {/* Button Section */}
      <div className={`mb-3 text-${createButtonPosition}`}>
        <input 
          ref={inputFileRef} 
          type="file" 
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{display: 'none'}} 
          onChange={onChangeFileHandler}
        />
        {Boolean(TYPE !== 'view' &&  showCreateButton) &&
          <ButtonCreate
            tooltip={false}
            text={createButtonText} 
            onClick={onClickFilesHandler} 
          />
        }
      </div>

      {/* Image Section */}
      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{cursor: 'pointer'}}
      >
        {filterImageHandler && filterImageHandler.length > 0
          ? <Row>
              {filterImageHandler.map((val, index) => (
                <Image 
                  key={index}
                  imageText={val.name}
                  imageUrl={val.link}
                  onView={e => {
                    e.stopPropagation()
                    setIndexImageHandler(index)
                  }}
                  onDownload={e => {
                    e.stopPropagation()
                    FileSaver.saveAs(val.link, val.name)
                  }}
                  onDelete={e => {
                    e.stopPropagation()
                    setModalDeleteConfig({
                      show: true,
                      loading: false,
                      index: val.index,
                      data: val.name
                    })
                  }}
                />
              ))}
            </Row>
          : <div className="d-flex flex-column justify-content-center align-items-center py-5">
              <HiPhotograph size={60} className="text-secondary"/>
              <span className="mt-2 text-secondary">Tidak ada gambar</span>
            </div>
        }
      </Container>

      {/* Modal */}
      <ModalDelete
        show={modalDeleteConfig.show} 
        title="Gambar"
        submitLoading={modalDeleteConfig.loading}
        onSubmit={onDeleteHandler}
        onHide={() => setModalDeleteConfig({
          show: false,
          index: 0,
          loading: false,
          data: ''
        })}
      >
        Data dengan nama gambar <b className="text-danger">{modalDeleteConfig.data}</b>
      </ModalDelete>
          
      {/*Image Viewer  */}
      {viewImageConfig.show &&
        <div style={{zIndex: 1000}}>
          <ImageViever 
            closeOnClickOutside
            disableScroll
            src={filterLinkHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({show: false, index: 0})}
            backgroundStyle={{zIndex: 10000}}
          />
        </div>
      }
    </div>
  )
}
