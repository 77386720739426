import React, {
  useState
} from 'react'
import {
  InputGroup,
  Form,
  FormControlProps,
  OverlayTrigger,
  Spinner,
  Tooltip,
  Image
} from 'react-bootstrap'
import ImageViever from 'react-simple-image-viewer'

interface DataInterface {
  data: any, 
  name: string, 
  link: string
}
interface InputImageSingleInterface extends Omit<FormControlProps, 'size' | 'onChange'> {
  label?: string,
  name?: string,
  data?: any,
  link?: string,
  loading?: boolean,
  loadingText?: string,
  valid?: boolean,
  validText?: string,
  error?: boolean,
  errorText?: string,
  errorFetch?: boolean,
  errorFetchText?: string,
  noMargin?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  tooltip?: boolean,
  tooltipText?: string,
  tooltipPlacement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start',
  size?: 'sm' | 'md' | 'lg' ,
  acceptFileType?: (string)[],
  preview?: boolean,
  previewWidth?: string | number,
  previewHeight?: string | number
  onChange?: (val: DataInterface) => void
}

export const InputImageSingle: React.FC<InputImageSingleInterface> = ({
  label,
  name,
  link,
  data,
  value,
  placeholder,
  loading,
  loadingText = 'Memuat data . . .',
  size = "sm",
  valid = false,
  validText,
  disabled,
  error = false, 
  errorText,
  errorFetch,
  errorFetchText = 'Data gagal dimuat',
  noMargin,
  className,
  wrapperClassName,
  tooltip,
  tooltipPlacement,
  tooltipText,
  acceptFileType = ['jpg', 'png', 'gif', 'jpeg'],
  preview = true,
  previewWidth,
  previewHeight = 200,
  onChange,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false)
  const [viewImage, setViewImage] = useState(false)

  // Menangani saat file dipilih
  const onChangeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    // Check apakah ada file yang dipilih
    if (!file) return null 
  
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1] 

    // Check jika type file sudah benar
    if (acceptFileType.includes(getFileType)) {
      const generateName = file.name
      const generateLink = URL.createObjectURL(file)
      const finalValue = {data: file, name: generateName, link: generateLink}
      
      return onChange && onChange(finalValue)
    } else {
      e.target.value = ''
      return window.alert(`Format file tidak valid, harap masukan file berformat ${acceptFileType.join(', ')}`)
    }
  }

  return (
    <>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
      >
        <Form.Group
          as="div"
          className={`${wrapperClassName ?? ''} ${noMargin ? 'm-0' : 'mb-2'}`}
        >
          <Form.Label as="small">{label}</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              {...props}
              type="file"
              value={loading ? '' : errorFetch ? '' : value}
              placeholder={loading ? loadingText : errorFetch ? errorFetchText : placeholder}
              size={size === "sm" ? "sm" : size === "lg" ? "lg" : undefined}
              className={`${className ? className : ''} rounded`}
              isInvalid={error}
              isValid={valid}
              readOnly={disabled 
                ? disabled
                : loading ? true : errorFetch ? true : false
              }
              accept="image/*"
              onChange={onChangeFileHandler}
            />
            {loading && 
              <InputGroup.Text style={{background: 'none', border: 'none'}}>
                <Spinner 
                  animation="border" 
                  size="sm" 
                  variant="secondary"
                />
              </InputGroup.Text>
            }
            <Form.Control.Feedback
              type={error ? 'invalid' : 'valid'}
              className="mt-0"
            >
              {validText}
              {errorText}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        </OverlayTrigger>
        {link && preview
          ? <Image 
              rounded 
              src={link} 
              height={previewHeight} 
              width={previewWidth} 
              className={`${isHover ? 'shadow' : 'shadow-sm'} border`}
              style={{objectFit: 'cover', transform: isHover ? 'scale(1.03)' : 'none', transition: 'all 0.1s ease-in-out', cursor: 'zoom-in'}}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              onClick={() => setViewImage(true)}
            />
          : ''
        }
        {viewImage &&
        <div style={{zIndex: 1000}}>
          <ImageViever 
            closeOnClickOutside
            disableScroll
            src={link ? [link] : []}
            currentIndex={0}
            onClose={() => setViewImage(false)}
            backgroundStyle={{zIndex: 10000}}
          />
        </div>
      }
      </>
  )
}