import React from 'react'
import {
  Chart
  as
  ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'
import type {
  ChartData,
  ChartOptions
} from 'chart.js'
import {
  Doughnut,
} from 'react-chartjs-2'
import {
  color
} from '../../../styles'

ChartJS.register(ArcElement, Tooltip, Legend)

interface DoughnutChartInterface {
  options: ChartOptions<'doughnut'>,
  data: ChartData<'doughnut'>
  showLegend: boolean,
  height: string | number,
  width: string | number
}

export const ChartDoughnut: React.FC<DoughnutChartInterface> = ({
  data,
  options,
  showLegend = true,
  ...props
}) => {
  const colorData = [
    color.colorInfo,
    '#0073b9',
    color.colorPrimary,
    '#cd0055',
    color.colorDanger,
    '#e64b1d',
    '#ed6914',
    color.colorWarning,
    '#bc9e00',
    '#869806',
    color.colorSuccess,
    '#009a98'
  ]

  return (
    <>
      <Doughnut 
        {...props}
        data={{
          labels: data.labels,
          datasets: data.datasets.map(val => ({
            ...val,
            backgroundColor: val?.backgroundColor ? val.backgroundColor : [...colorData, ...colorData],
            borderWidth: val.borderWidth ? val.borderWidth : 1
          }))
        }}
        options={{
          ...options,
          backgroundColor: options?.backgroundColor ? options.backgroundColor : color.colorPrimary,
          plugins: {
            ...options?.plugins,
            legend: {
              ...options?.plugins?.legend,
              display: showLegend,
            }
          }
        }}
      />
    </>
  )
}
