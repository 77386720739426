import React from 'react';
import {
  Button as BSButton,
  ButtonProps,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
export interface ButtonInterface extends Omit<ButtonProps, 'size'> {
  icon?: boolean;
  text?: string | number | boolean | null | undefined;
  loading?: boolean;
  loadingText?: string | number | null | undefined;
  tooltip?: boolean;
  tooltipText?: string | number | null | undefined;
  tooltipPlacement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  size?: 'sm' | 'md' | 'lg';
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
}

export const Button: React.FC<ButtonInterface> = ({
  text,
  variant,
  children,
  disabled,
  loading,
  loadingText,
  tooltip,
  tooltipText,
  tooltipPlacement,
  className,
  size = 'sm',
  ...rest
}) => {
  const checkIsVariantOutline = (variant: String) =>
    Boolean(variant.substring(0, 7) === 'outline');

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
    >
      <BSButton
        {...rest}
        size={size === 'sm' ? 'sm' : size === 'lg' ? 'lg' : undefined}
        className={`${className ?? ''} ${
          variant === 'warning' || variant === 'info' ? 'text-white' : ''
        }`}
        variant={variant ?? 'primary'}
        disabled={loading ? true : disabled ? true : false}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <Spinner
                animation="border"
                variant={
                  checkIsVariantOutline(variant ?? 'primary')
                    ? variant
                    : 'white'
                }
                size="sm"
              />
              <div
                className={`ms-2 ml-2 ${
                  variant === 'warning' || variant === 'info'
                    ? 'text-white'
                    : ''
                }`}
              >
                {loadingText ?? 'Memproses . . .'}
              </div>
            </div>
          </div>
        ) : (
          <span className="text-uppercase">
            {children}
            {text}
          </span>
        )}
      </BSButton>
    </OverlayTrigger>
  );
};
